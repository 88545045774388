import { APP_CONSTANT } from "../constant";
import AppUtils from "./utils";

export class SharingUtil {

    static FormatMailContent(subject, body, sharing_url) {
        let output = this.HtmlToText(body);

        output += APP_CONSTANT.MAIL_NEW_LINE;
        output += APP_CONSTANT.MAIL_NEW_LINE;

        output += encodeURIComponent(sharing_url);

        return 'mailto:?subject=' + subject + '&body=' + output;
    }

    static FormatWhatsAppContent(title, content, sharing_url) {
        let message = '';
        message = title + this.HtmlToText(content);
        message += '\r\n';
        message += '\r\n';
        message += sharing_url;
        message = encodeURIComponent(message);
        if (AppUtils.IsMobile()) {
            return 'whatsapp://send?text=' + message;
        } else {
            return 'https://web.whatsapp.com/send?text=' + message;
        }
    }

    static ReplaceAll(strings, search, replacement) {
        let result = strings;
        if (Array.isArray(search)) {
            for (let i in search) {
                result = result.replace(new RegExp(search[i], 'g'), replacement);
            }
        } else {
            result = result.replace(new RegExp(search, 'g'), replacement);
        }
        return result;
    }

    static HtmlToText(html) {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent;
    }
}
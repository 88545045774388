import { BrowserRouter, Switch, Route } from "react-router-dom";
import NavigationBar from "../components/navigation-bar/navigation-bar";
import { APP_ROUTE_KEY } from "../constant";
import { AdsDetailPage, BloomrPage, ContactPage, ContentPage, DigitalAdsPage, LandingPage, WebsitePage, ThankYouPage } from '../pages';

const AppRouter = (prop) => {
    return (
        <BrowserRouter>
            <div className='app-wrapper'>
                <NavigationBar />
                <Switch>
                    <main className="section-body">
                        <Route exact path={APP_ROUTE_KEY.home}>
                            <LandingPage />
                        </Route>
                        <Route exact path={APP_ROUTE_KEY.digital_ads}>
                            <DigitalAdsPage />
                        </Route>
                        <Route path={APP_ROUTE_KEY.website}>
                            <WebsitePage />
                        </Route>
                        <Route exact path={APP_ROUTE_KEY.content}>
                            <ContentPage />
                        </Route>
                        <Route exact path={APP_ROUTE_KEY.bloomr}>
                            <BloomrPage />
                        </Route>
                        <Route exact path={APP_ROUTE_KEY.contact}>
                            <ContactPage />
                        </Route>
                        <Route path={APP_ROUTE_KEY.ads_detail}>
                            <AdsDetailPage />
                        </Route>
                        <Route path={APP_ROUTE_KEY.thank_you}>
                            <ThankYouPage />
                        </Route>
                        {/* <Route render={() => <Redirect to={{ pathname: "/" }} />} /> */}
                    </main>
                </Switch>
            </div>
        </BrowserRouter>
    )
}

export default AppRouter;

const pool = new Map();

/**
 * 
 * @param {[string]} sources
 * @param {Promise<>} 
 */

const loadScript = (sources) => {

    const loader = src => {
        if (pool.has(src)) {
            return new Promise(next => {
                let temp = pool.get(src);
                if (typeof temp === 'boolean' && temp) {
                    next();
                } else {
                    temp.push(next);
                    pool.set(src, temp);
                }
            });
        }
        return new Promise(next => {
            pool.set(src, []);
            let countLoop = 0;
            const fn = () => {
                countLoop++;
                let script;
                if (src.indexOf('.js') > -1) {
                    script = document.createElement("script");
                    script.src = src;
                    script.type = 'text/javascript';
                    const firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

                } else if (src.indexOf('.css') > -1) {
                    script = document.createElement("link");
                    script.href = src;
                    script.type = 'text/css';
                    script.rel = 'stylesheet';
                    const firstScriptTag = document.getElementsByTagName('link')[0];
                    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
                }
                script.onload = () => {
                    next();
                    const cbs = pool.get(src);
                    let cb;
                    while (!!(cb = cbs.shift())) {
                        cb();
                    }
                    pool.set(src, true);
                }
                script.onerror = () => {
                    if (countLoop > 5) {
                        next();
                        const cbs = pool.get(src);
                        let cb;
                        while (!!(cb = cbs.shift())) {
                            cb();
                        }
                        pool.set(src, true);
                        return;
                    }
                    setTimeout(fn, 1000);
                }
            }
            fn();
        });
    }

    const all = sources.map(loader);

    return Promise.all(all);
}

export { loadScript };
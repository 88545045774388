
import { APP_COLOR, APP_CONSTANT, APP_STRING } from "../../../constant"
import useSetClassRoot from "../../../hooks/useSetClassRoot";
import AdsFilterBasePage from "../base-ads.page";

const WebsitePage = () => {

    useSetClassRoot({ className: 'page-website' })

    return (
        <AdsFilterBasePage
            pageName={APP_STRING.page_websites_name}
            mainColor={APP_COLOR.website}
            showFilterEntries={[APP_CONSTANT.ADS_FILTER.show_format, APP_CONSTANT.ADS_FILTER.show_feature, APP_CONSTANT.ADS_FILTER.show_industry]}
        />
    )
}

export default WebsitePage;

const APP_CONSTANT = {
    footer_show_all: 'all',
    footer_show_logo: 'logo',
    footer_show_contact: 'contact',
    footer_show_sharing: 'sharing',
    MAIL_NEW_LINE: '%0d%0a',
    WHATAPP_NEW_LINE: '\r\n',
    GCATCHA_KEY: process.env.REACT_APP_GCATCHA_KEY || '',
    ADS_FILTER: {
        show_all: 'all',
        show_format: 'format',
        show_feature: 'feature',
        show_device: 'device',
        show_industry: 'industry',
    },
    ADS_TYPE: {
        image: 'ads_image',
        video: 'ads_video',
        podcast: 'ads_podcast',
        default: 'ads_image'
    },
    ADS_SLIDER_TYPE: {
        default: 'slider_image',
        image: 'slider_image',
        video: 'slider_video',
        youtube: 'slider_youtube',
        podcast: 'slider_podcast'
    },
    no_card_per_times: 9,
    SPLASH_DURATION: 2000
}

Object.freeze(APP_CONSTANT);

export default APP_CONSTANT;


import queryString from 'query-string';


class AppUtils {

    static AreArrayEqual(arr1 = [], arr2 = []) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let k1 in arr1) {
            if (arr2.indexOf(arr1[k1]) === -1) {
                return false;
            }
        }
        return true;
    }

    static HexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    static RandomInteger(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    /**
     * 
     * @param {string} src 
     * @returns {Promise<{naturalWidth, naturalHeight}>}
     */
    static LoadImageSize(src) {
        return new Promise(next => {
            const image = new Image();
            image.onload = () => {
                const { naturalWidth, naturalHeight, src } = image;
                next({ naturalWidth, naturalHeight, image, src });
            }
            image.onerror = () => {
                next({ naturalWidth: 360, naturalHeight: 360, src: image.src });
            }
            image.src = src;
        })
    }

    /**
     * 
     * @param {[any]]} input
     * @returns {[any]} 
     */
    static CloneArray(input) {
        return input.map(item => {
            return Array.isArray(item) ? AppUtils.CloneArray(item) : item
        });
    }

    static StringRelaceAll(str, find, replacement) {
        let re = new RegExp(find, 'g');
        return str.replace(re, replacement)
    }

    /**
     * 
     * @param {string} search
     * @return {Object} 
     */
    static QueryStringParams(search) {
        return queryString.parse(search);
    }

    static ValidateEmail(value) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
            return true;
        }
        return false;
    }

    static IsIOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    static IsMobile() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
}

export default AppUtils;
import { APP_STRING } from "../constant";
import AppUtils from "../utils/utils";
import BaseModel from "./base.model";

export class ContactModel extends BaseModel {
    name = '';
    email = '';
    phone = '';
    content = '';
    allow_privacy = false;

    toJson() {
        return {
            name: this.name.trim(),
            email: this.email.trim(),
            phone: this.phone.trim(),
            content: this.content.trim(),
        }
    }

    toValidate() {
        return {
            isError: false,
            name: '',
            email: '',
            phone: '',
            content: '',
            privacy: ''
        }
    }

    validate() {
        var rs = this.toValidate();
        if (this.name.trim().length === 0) {
            rs.isError = true;
            rs.name = APP_STRING.error_empty_name;
        }

        if (this.email.trim().length === 0) {
            rs.isError = true;
            rs.email = APP_STRING.error_empty_email;
        } else if (AppUtils.ValidateEmail(this.email.trim()) === false) {
            rs.isError = true;
            rs.email = APP_STRING.error_email_format;
        }

        if (this.phone.trim().length === 0) {
            rs.isError = true;
            rs.phone = APP_STRING.error_empty_phone;
        } else if (this.phone.trim().length !== 8) {
            rs.isError = true;
            rs.phone = APP_STRING.error_phone_length;
        }

        if (this.content.trim().length === 0) {
            rs.isError = true;
            rs.content = APP_STRING.error_empty_message;
        } else if (this.content.trim().length > 500) {
            rs.isError = true;
            rs.content = APP_STRING.error_message_length;
        }

        if (this.allow_privacy === false) {
            rs.isError = true;
            rs.privacy = APP_STRING.error_empty_consent;
        }
        return rs;
    }

    clone() {
        const newObject = new ContactModel();
        for (let key in this) {
            newObject[key] = this[key];
        }
        return newObject;
    }

}


import { useEffect, useState } from "react";
import ApiHelper from "../api/api";
import { APP_CONSTANT } from "../constant";

const FILTER_FETCH_TYPE = APP_CONSTANT.ADS_FILTER;

const ALL_FILTERS = [FILTER_FETCH_TYPE.show_format, FILTER_FETCH_TYPE.show_feature, FILTER_FETCH_TYPE.show_device, FILTER_FETCH_TYPE.show_industry];

const useFetchFilterOptions = (pageType,
    filterTypes = [FILTER_FETCH_TYPE.show_all]
) => {

    let needFilters = filterTypes;
    if (filterTypes.indexOf(FILTER_FETCH_TYPE.show_all) > -1) {
        needFilters = [FILTER_FETCH_TYPE.show_format, FILTER_FETCH_TYPE.show_feature, FILTER_FETCH_TYPE.show_device, FILTER_FETCH_TYPE.show_industry];
    }

    const [results, setResults] = useState({
        formatFilters: [], featureFilters: [], deviceFilters: [], industryFilters: []
    });

    useEffect(() => {
        const allPromise = ALL_FILTERS.map(type => {
            if (FILTER_FETCH_TYPE.show_format === type && needFilters.indexOf(type) > -1) {
                return ApiHelper.GetFormatFilterByPage(pageType);
            } else if (FILTER_FETCH_TYPE.show_feature === type && needFilters.indexOf(type) > -1) {
                return ApiHelper.GetFeatureFilterByPage(pageType);
            } else if (FILTER_FETCH_TYPE.show_device === type && needFilters.indexOf(type) > -1) {
                return ApiHelper.GetDeviceFilterByPage(pageType);
            } else if (FILTER_FETCH_TYPE.show_industry === type && needFilters.indexOf(type) > -1) {
                return ApiHelper.GetIndustryFilterByPage(pageType);
            } else {
                return Promise.resolve([]);
            }
        })
        Promise.all(allPromise).then(outputs => {
            setResults({
                formatFilters: outputs[0],
                featureFilters: outputs[1],
                deviceFilters: outputs[2],
                industryFilters: outputs[3]
            });
        });
    }, []);
    return results;
}

export { FILTER_FETCH_TYPE, useFetchFilterOptions };
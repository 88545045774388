import AppRouter from "./routes/router";
import './css/common.scss';
import showFullLoading from "./hooks/useFullLoading";
import { useEffect, useState } from "react";
import { APP_CONSTANT } from "./constant";
import useGtag from "./hooks/useGTag";

function App() {
  const [isFirstRun, setFirstRun] = useState(true);
  if (isFirstRun) {
    showFullLoading({ isShow: true });
  }
  useEffect(() => {
    setTimeout(() => {
      showFullLoading({ isShow: false });
      setFirstRun(false);
    }, APP_CONSTANT.SPLASH_DURATION !== undefined ? APP_CONSTANT.SPLASH_DURATION : 3000);
  }, []);
  return (
    <>
      <AppRouter>
      </AppRouter>
    </>
  );
}

export default App;

import './ads-detail.page.scss';
import { APP_CONSTANT, APP_STRING } from "../../constant";
import useFetchAdsDetail from "../../hooks/useFetchAdsDetail"
import useSetClassRoot, { setClassRoot } from "../../hooks/useSetClassRoot";
import AppFooter from '../../components/footer/footer';
import { useHistory } from 'react-router-dom';
import { useScrollTo } from '../../hooks/useScroll';
import AdsCarousel from '../../components/ads-carousel/ads-carousel';
import MCPage from '../../widgets/mc-page/mc-page';
import AppUtils from '../../utils/utils';
import MCLoading from '../../widgets/loading/mc-loading';


const isContainSpecLink = adsModel => typeof adsModel.spec_link === 'string' && adsModel.spec_link.length > 0;

const AdsDetailPage = ({ adsId = 0 }) => {

    const history = useHistory()
    const params = AppUtils.QueryStringParams(history.location.search);
    adsId = params.id;


    const adsDetailModel = useFetchAdsDetail({ adsId });
    let isBloomrPage = false;

    useSetClassRoot({ className: 'page-ads-detail' });

    if (typeof adsDetailModel === 'object') {
        switch (adsDetailModel.page_name.toLowerCase()) {
            case 'digital ads':
                setClassRoot('page-digital-ads');
                break;
            case 'website':
                setClassRoot('page-website');
                break;
            case 'bloomr.sg':
                setClassRoot('page-bloomr');
                isBloomrPage = true;
                break;
            case 'content':
                setClassRoot('page-content');
                break;
            default:
                break;
        }
    }

    useScrollTo(0);
    return (
        <MCPage className='app-page'>
            {
                adsDetailModel == null && <div className='wrap-loading'> <MCLoading /></div>
            }
            {adsDetailModel === 'not-found' && <NotFound />}
            {
                adsDetailModel != null && adsDetailModel !== 'not-found' &&
                <div className='ads-detail-page anim-fade-in'>
                    <section className='section-banner'>
                        <AdsCarousel adsBanners={adsDetailModel.sliders} />
                        <div className='banner-holder'
                        // style={{ backgroundImage: `url(${adsDetailModel.banner})` }}
                        >
                        </div>
                    </section>
                    <section className='section-action'>
                        <a className='btn-white btn-action btn-view' href={adsDetailModel.creative_link} target='_blank' rel='noreferrer'>
                            <span>{APP_STRING.detail_button_view}</span>
                        </a>
                        {
                            isContainSpecLink(adsDetailModel) &&
                            <a className='btn-white btn-action btn-back' href={adsDetailModel.spec_link} target='_blank' rel='noreferrer'>
                                <span>{APP_STRING.detail_button_view_specification}</span>
                            </a>
                        }
                    </section>
                    <section className='section-content'>
                        <RowTextData
                            text={APP_STRING.detail_label_client}
                            value={adsDetailModel.toClientString()}
                        />
                        {adsDetailModel.toIndustryString().length > 0 &&
                            <RowTextData
                                text={APP_STRING.detail_label_industry}
                                value={adsDetailModel.toIndustryString()}
                            />
                        }
                        {adsDetailModel.toFormatString().length > 0 &&
                            <RowTextData
                                text={APP_STRING.detail_label_format}
                                value={adsDetailModel.toFormatString()}
                            />
                        }
                        {adsDetailModel.toFeatureString().length > 0 &&
                            <RowTextData
                                text={isBloomrPage ? APP_STRING.detail_label_features_bloorm : APP_STRING.detail_label_features}
                                value={adsDetailModel.toFeatureString()}
                            />
                        }
                        {adsDetailModel.toDeviceString().length > 0 &&
                            <RowTextData
                                text={APP_STRING.detail_label_device}
                                value={adsDetailModel.toDeviceString()}
                            />
                        }
                    </section>
                    <AppFooter
                        showEntries={[APP_CONSTANT.footer_show_sharing]}
                        mailSharing={adsDetailModel.toMailSharing()}
                        whatappSharing={adsDetailModel.toWhatsappSharing()}
                    />

                </div>
            }
        </MCPage>
    )
}

const RowTextData = ({ text = '', value = '' }) => {
    return <div className='row row-data'>
        <span className='lb-title'>
            {text}
        </span>
        <span className='lb-value'>
            {value}
        </span>
    </div>
}


const NotFound = () => {
    return (
        <>
            <section className='section-top --no-result'>
                <div className='row'>
                    <span className='lb-page-name'>
                        {APP_STRING.filter_no_result_title}
                    </span>
                </div>
            </section>
        </>
    )
}



export default AdsDetailPage;
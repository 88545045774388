import { forwardRef, useCallback } from 'react';
import './mc-input.scss';

const MCInput = forwardRef(({ type = 'text', value = '', inputKey = '', errorMsg = '', placeHolder = '', onTextChange = () => { }, ...rest }, ref) => {

    const onChange = useCallback((e) => {
        onTextChange(e.target.value, inputKey);
    }, [inputKey, onTextChange]);

    return (
        <div className='mc-input'>
            <div className='mc-input__holder'>
                <input ref={ref} type={type} placeholder={placeHolder} onChange={onChange} />
            </div>
            {errorMsg.length > 0 &&
                <span class='lb-error'>{errorMsg}</span>}
        </div >
    )
})

export default MCInput;
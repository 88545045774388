
let instanceLoading = null;

const showFullLoading = ({ isShow = true, background = '' }) => {

    const hideLoading = () => {
        if (instanceLoading != null) {
            instanceLoading.classList.add('hideLoading');
            setTimeout(() => {
                if (instanceLoading != null) {
                    document.body.removeChild(instanceLoading);
                }
                instanceLoading = null;
            }, 400);
        }
    };

    if (instanceLoading == null && isShow) {
        instanceLoading = document.createElement('div');
        instanceLoading.classList.add('page-loading');
        instanceLoading.style.background = background;
        instanceLoading.innerHTML = '<mc-loading></mc-loading>';
        document.body.appendChild(instanceLoading);
    } else if (!isShow && instanceLoading != null) {
        hideLoading();
    }

    return hideLoading;
}

const isLoadingShowing = () => {
    return instanceLoading !== null;
}

export default showFullLoading;

export {
    showFullLoading,
    isLoadingShowing
}
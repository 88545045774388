
const MCDescription = ({ content = [] }) => {
    return <>
        {
            content.reduce((out, text) => {
                out.push(text);
                return out;
            }, [])
        }
        <br/>
    </>
}

export default MCDescription;
import { APP_CONSTANT } from "../constant";
import AppUtils from "../utils/utils";
import BaseModel from "./base.model";


class AdsBannerModel extends BaseModel {

    source = '';
    type = APP_CONSTANT.ADS_SLIDER_TYPE.default;
    thumbnail = `https://loremflickr.com/${AppUtils.RandomInteger(720, 1600)}/${AppUtils.RandomInteger(320, 600)}`;
    imageLink = '';

    random() {
        this.source = `https://loremflickr.com/${AppUtils.RandomInteger(720, 1600)}/${AppUtils.RandomInteger(320, 600)}`;

        let random = AppUtils.RandomInteger(1, 9);
        if (random % 2 === 0) {
            this.source = 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4';
            this.type = APP_CONSTANT.ADS_SLIDER_TYPE.video;
        } else if (random % 3 === 0) {
            this.type = APP_CONSTANT.ADS_SLIDER_TYPE.youtube;
            this.source = 'https://www.youtube.com/watch?v=1yVqSSDRu78';
        } else if (random) {
            this.type = APP_CONSTANT.ADS_SLIDER_TYPE.podcast;
            this.source = 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3';
        }
        return this;
    }

    fromJson(obj) {
        if (obj.video_url !== null) {
            if (obj.video_url.indexOf('youtube.com') > -1) {
                this.type = APP_CONSTANT.ADS_SLIDER_TYPE.youtube;
            } else {
                this.type = APP_CONSTANT.ADS_SLIDER_TYPE.video;
            }
            this.source = obj.video_url;
        } else if (obj.img_url !== null) {
            this.type = APP_CONSTANT.ADS_SLIDER_TYPE.image;
            this.source = obj.img_url;
            this.imageLink = obj.external_url;
        } else if (obj.external_url != null) {
            this.type = APP_CONSTANT.ADS_SLIDER_TYPE.podcast;
            this.source = obj.external_url;
        }
        this.thumbnail = obj.preview_img_url;
        return this;
    }

}

export default AdsBannerModel;
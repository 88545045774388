import { useState } from "react"
import { useRouteMatch } from "react-router-dom";
import { APP_ROUTE_KEY } from "../constant";


const _root = document.getElementById('mc-app-root');

const useSetClassRoot = ({ className }) => {

    const [hasSetClass, setHasSetClass] = useState(false);


    if (hasSetClass === false) {
        setClassRoot(className);
    }

    // useEffect(() => {
    //     setHasSetClass(true);
    // },[]);
    // }, [])
    // useEffect(() => {
    //     _root.classList.add(className);
    //     return () => {
    //         _root.classList.remove(className);
    //     }
    // });
}

const setClassRoot = className => {
    // useEffect(() => {
    const mappingDetails = APP_ROUTE_KEY.ads_detail.map(useRouteMatch);
    let isDetailPage = false;
    for (let key in mappingDetails) {
        let item = mappingDetails[key];
        isDetailPage = item && item.isExact;
        if (isDetailPage) break;
    }

    if (!isDetailPage) {
        _root.className = '';
    }
    _root.classList.add(className);
}

export default useSetClassRoot;
export { useSetClassRoot, setClassRoot }
import "./lib-carousel.css";
import "./ads-carousel.scss";
import { AdsBannerModel } from "../../models";
import { Carousel } from 'react-responsive-carousel';
import { APP_CONSTANT } from "../../constant";
import { forwardRef, useCallback, useRef, useState } from "react";
import MCLoading from "../../widgets/loading/mc-loading";
import AppUtils from "../../utils/utils";


const _config = {
    PAUSE_VIDEO_WHEN_CHANGE_SLIDE: true
}



/**
 * 
 * @param {[*]} refs 
 */
const filterPlayersRefs = (refs) => refs.filter(i => i.current?.nodeName === 'MC-YTPLAYER' || i.current?.nodeName === 'MC-PLAYER');

const pauseAllPlayers = (refs) => {
    filterPlayersRefs(refs).forEach(({ current: customElement }) => {
        if (customElement.setPauseVideo !== undefined)
            customElement.setPauseVideo(true);
    })
}


const rebuildFacebookUrl = fbUrl => {
    const urls = fbUrl.split('?');
    let params;
    if (urls.length > 1) {
        params = AppUtils.QueryStringParams(urls[1]);
        // params.width = window.innerWidth * 0.9;
        // params.height = window.innerWidth * 0.5;

        delete params.width;
        delete params.height;

        params.container_width = Math.floor((window.innerWidth - 20) * 9 / 6);
        params.allowfullscreen = 'true';
        params.sdk = 'joey';

        const strParams = Object.keys(params).reduce((out, key) => {
            out.push(key + '=' + params[key]);
            return out;
        }, []);

        return urls[0] + '?' + strParams.join('&');
        // return 'https://www.facebook.com/v9.0/plugins/video.php?' + strParams.join('&');
    }
    return fbUrl;
}


/**
 * 
 * @param {{adsBanners: [AdsBannerModel]}} param0 
 */
const AdsCarousel = ({
    adsBanners = Array.from(new Array(5)).map(() => new AdsBannerModel().random())
}) => {

    const [pauseVideo, setPauseVideo] = useState(true);

    const bannerRefs = adsBanners.map(useRef);

    const isImageType = adsBanners.length > 0 && adsBanners[0].type === APP_CONSTANT.ADS_SLIDER_TYPE.image;

    const showImageViewers = useCallback((targetIndex) => {
        const bannerTarget = adsBanners[targetIndex];
        if (!(bannerTarget.type === APP_CONSTANT.ADS_SLIDER_TYPE.image && (bannerTarget.imageLink === null || bannerTarget.imageLink.length === 0))) {
            return;
        }

        const images = adsBanners.filter(i => {
            return i.type === APP_CONSTANT.ADS_SLIDER_TYPE.image && (i.imageLink === null || i.imageLink.length === 0);
        }).map(item => {
            return {
                src: item.source
            }
        });

        if (images.length === 0) {
            return
        }
        const imageViewer = document.createElement('mc-image-viewer');
        imageViewer.setAttribute('data-images', JSON.stringify({
            index: targetIndex,
            images: images
        }))
        document.body.appendChild(imageViewer);
    }, [adsBanners])

    const onSliderChange = useCallback(() => {
        if (_config.PAUSE_VIDEO_WHEN_CHANGE_SLIDE) {
            pauseAllPlayers(bannerRefs);
        }
    }, [bannerRefs]);

    return (
        <div className={`mc-carousel${adsBanners.length > 1 ? ' show-paging' : ''}${isImageType ? ' type-image' : ''}`}>
            <Carousel
                className='carousel-holder'
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                autoPlay={false}
                swipeable={true}

                onChange={onSliderChange}
            >
                {
                    adsBanners.map((item, index) => {
                        switch (item.type) {
                            case APP_CONSTANT.ADS_SLIDER_TYPE.image:
                                return <BannerImage
                                    key={item.id}
                                    source={item.source}
                                    onClick={() => { showImageViewers(index) }}
                                    link={item.imageLink} />
                            case APP_CONSTANT.ADS_SLIDER_TYPE.youtube:
                                return <BannerYoutube ref={bannerRefs[index]} key={item.id} source={item.source} pause={pauseVideo} />
                            case APP_CONSTANT.ADS_SLIDER_TYPE.video:
                                return <BannerVideo
                                    ref={bannerRefs[index]}
                                    key={item.id}
                                    source={item.source}
                                    thumbnail={item.thumbnail}
                                    pause={pauseVideo} />
                            case APP_CONSTANT.ADS_SLIDER_TYPE.podcast:
                                return <BannerPodcast
                                    ref={bannerRefs[index]}
                                    key={item.id}
                                    source={item.source}
                                    pause={pauseVideo}
                                    thumbnail={item.thumbnail}
                                />
                            default:
                                return <></>
                        }
                    })
                }
            </Carousel>
        </div>

    )
}

const BannerImage = ({ source = '', link = '#', onClick = () => { } }) => {
    return (
        <a href={link} target='_blank' rel="noreferrer" onClick={onClick} >
            <div className='banner-image' style={{ backgroundImage: `url('${source}')` }}></div>
        </a>
    )
}

const BannerVideo = forwardRef(({ source = '', pause = true, thumbnail = '' }, ref) => {
    return (
        <div className='banner-video'>
            <mc-player ref={ref} data-poster={thumbnail} data-video-url={source} data-paused={pause}></mc-player>
        </div>
    )
});

/**
 * // Ref: web-components/youtube-player
 * @param {*} param0 
 */
const BannerYoutube = forwardRef(({ source = '', pause = true, thumbnail = '' }, ref) => {
    return (
        <div className='banner-youtube'>
            <mc-ytplayer ref={ref} data-video-url={source} data-paused={pause}></mc-ytplayer>
        </div>
    )
})

const BannerPodcast = forwardRef(({ source = '', pause = true, thumbnail = '' }, ref) => {
    const isIframe = source.indexOf('<iframe') > -1,
        isFile = source.indexOf('.mp3') > -1 || source.indexOf('.mp4') > -1,
        isFacebook = source.indexOf('facebook.com') > -1;
    const refHolder = useRef();

    const refLoading = useRef();


    const loadedIFrame = useCallback((e) => {
        if (!!refHolder.current) {
            refHolder.current.style.background = 'none';
        }
        if (!!refLoading.current) {
            refLoading.current.style.display = 'none';
        }
        if (isFacebook) {
            e.currentTarget.style.maxWidth = e.currentTarget.offsetHeight * 9 / 6 + 'px';
        }
        e.currentTarget.style.opacity = 1;
    }, [isFacebook]);

    if (isFacebook) {
        source = rebuildFacebookUrl(source);
    }

    return (
        <div ref={refHolder} className='banner-podcast' style={{ transition: 'all .2s linear', backgroundImage: `url('${thumbnail}')` }} >
            {
                (isIframe && !isFile) &&
                <div dangerouslySetInnerHTML={{ __html: source }}></div>
            }
            {
                (!isIframe && isFile) &&
                <mc-player ref={ref} data-video-url={source} data-poster={thumbnail} data-paused={pause}></mc-player>
            }
            {
                !isIframe && !isFile &&
                <>
                    <iframe
                        title={source}
                        src={source}
                        key={source}
                        allowFullScreen={true}
                        style={{ opacity: 0, height: isFacebook ? '100%' : 'auto' }}
                        allow="encrypted-media; picture-in-picture;"
                        scrolling='no'
                        onLoad={loadedIFrame} onError={(e) => {
                            loadedIFrame();
                        }} ></iframe>
                    <div className='loading-holder' ref={refLoading} >
                        <MCLoading />
                    </div>
                </>
            }
        </div>
    )
});

export default AdsCarousel;
import { useCallback, useState } from "react";
import FilterBanner from "../../components/filter-banner/filter-banner"
import { APP_CONSTANT } from "../../constant"
import { useFetchFilterOptions } from "../../hooks/useFetchFilterOption";
import MCPage from "../../widgets/mc-page/mc-page";
import AdsFilterResult from "../../components/ads-filter-result/ads-filter-result";
import AppFooter from '../../components/footer/footer'
import AppUtils from "../../utils/utils";
import { useHistory, useRouteMatch } from "react-router-dom";


const encodeFilter = obj => {
    return Object.keys(obj).reduce((out, k) => {
        out[k] = encodeURIComponent(obj[k]);
        return out;
    }, {})
};
const decodeFilter = obj => {
    return Object.keys(obj).reduce((out, k) => {
        out[k] = decodeURIComponent(obj[k]);
        return out;
    }, {})
};

const getSearchParams = (filter) => {
    let result = [];
    if (typeof filter.format === 'string' && filter.format.length > 0) {
        result.push(`format=${filter.format}`);
    }
    if (typeof filter.format === 'string' && filter.feature.length > 0) {
        result.push(`feature=${filter.feature}`);
    }
    if (typeof filter.format === 'string' && filter.device.length > 0) {
        result.push(`device=${filter.device}`);
    }
    if (typeof filter.format === 'string' && filter.industry.length > 0) {
        result.push(`industry=${filter.industry}`);
    }
    return result.join('&');
}

const AdsFilterBasePage = ({
    pageName = 'Page Name',
    mainColor = '#000000',
    showFilterEntries = [APP_CONSTANT.ADS_FILTER.show_all],
    allowBackupState = true
}) => {

    const { formatFilters, featureFilters, deviceFilters, industryFilters } = useFetchFilterOptions(pageName, showFilterEntries)


    const history = useHistory(),
        pathMatch = useRouteMatch();

    const params = decodeFilter(AppUtils.QueryStringParams(history.location.search));

    const defaultOptionValue = {
        format: '',
        feature: '',
        device: '',
        industry: '',
        ...params
    };

    const [filter, setFilter] = useState({ format: "", feature: "", device: "", industry: "", ...defaultOptionValue })


    const onFilterApplied = useCallback(filterObj => {
        setFilter(filterObj);
        const filterEncoded = encodeFilter(filterObj);
        history.replace({
            pathname: pathMatch.path,
            search: '?' + getSearchParams(filterEncoded)
        })
    }, []);

    const onResultsChange = useCallback(({ results, filterKeys: { format, device, industry, feature } }) => {
    });

    return (
        <MCPage className='app-page'>
            <FilterBanner
                name={pageName}
                mainColor={mainColor}
                showEntries={showFilterEntries}
                featureFilters={featureFilters}
                formatFilters={formatFilters}
                deviceFilters={deviceFilters}
                industryFilters={industryFilters}
                onFilterApplied={onFilterApplied}
                defaultFormatValue={defaultOptionValue.format}
                defaultFeatureValue={defaultOptionValue.feature}
                defaultDeviceValue={defaultOptionValue.device}
                defaultIndustryValue={defaultOptionValue.industry}
            />
            <AdsFilterResult
                page={pageName}
                format={filter.format}
                feature={filter.feature}
                device={filter.device}
                industry={filter.industry}
                onResultsChange={onResultsChange}

            />
            <AppFooter showEntries={[APP_CONSTANT.footer_show_logo, APP_CONSTANT.footer_show_contact]} />
        </MCPage>
    )
}

export default AdsFilterBasePage;
import './thankyou.page.scss';
import { APP_CONSTANT, APP_STRING } from "../../constant";
import { useScrollTo } from "../../hooks/useScroll";
import useSetClassRoot from "../../hooks/useSetClassRoot";
import MCPage from '../../widgets/mc-page/mc-page';
import AppFooter from '../../components/footer/footer';

const ThankYouPage = () => {
    const thankYou = APP_STRING.thank_you;
    useSetClassRoot({ className: 'page-thank' });
    useScrollTo(0);

    return (
        <MCPage>
            <section className='section-top --thank-you'>
                <div className='row'>
                    <span className='lb-page-name'>
                        {APP_STRING.page_contact_thank_you}
                    </span>
                </div>
                <div className='row'>
                    {
                        thankYou.map(str => <p>{str}</p>)
                    }
                </div>
            </section>
            <AppFooter showEntries={[APP_CONSTANT.footer_show_logo, APP_CONSTANT.footer_show_contact]} />
        </MCPage>
    )
}

export default ThankYouPage
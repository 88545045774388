import { useEffect } from "react";


const _scroller = window;

const LIMIT_SCROLL = 100;
let subscriblesScrollEnd = new Set(),
    subscriblesScroll = new Set(),
    hasCalled = false,
    waiterScrollEnd;

const getPageHeight = () => {
    return document.body.offsetHeight;
}

const getScrollY = () => {
    return _scroller.scrollY;
}



const setAlwaysShowScroll = (flag) => {
    // window.scrollbars.visible = flag;
}

let waiterObserveScroll;
_scroller.addEventListener("scroll", (e) => {

    let current = getScrollY() + _scroller.innerHeight, pageHeight = getPageHeight();
    clearTimeout(waiterObserveScroll);
    waiterObserveScroll = setTimeout(() => {
        subscriblesScroll.forEach(f => {
            if (typeof f === 'function') {
                f(getScrollY());
            }
        });
    }, 5);


    if (!hasCalled && current + LIMIT_SCROLL > pageHeight) {
        hasCalled = true;

        setTimeout(() => { hasCalled = false }, 1000);

        clearTimeout(waiterScrollEnd);
        waiterScrollEnd = setTimeout(() => {
            subscriblesScrollEnd.forEach(f => {
                if (typeof f === 'function') {
                    f();
                }
            })
        }, 100);
    }

});


const useScrollEnd = (cb, depends) => {
    useEffect(() => {
        subscriblesScrollEnd.add(cb);
        return function unsubscrible() {
            subscriblesScrollEnd.delete(cb);
        }
    }, [cb]);
    return [
        function unsubscrible() {
            subscriblesScrollEnd.delete(cb);
        },
        function setPause(flag) {
            if (flag) {
                subscriblesScrollEnd.add(cb);
            } else {
                subscriblesScrollEnd.delete(cb);
            }
        }]
}


const useScroll = (cb) => {
    useEffect(() => {
        subscriblesScroll.add(cb);
        return function unsubscrible() {
            subscriblesScroll.delete(cb);
        }
    }, [cb])
    return [
        function unsubscrible() {
            subscriblesScroll.delete(cb);
        },
        function setPause(flag) {
            if (flag) {
                subscriblesScroll.add(cb);
            } else {
                subscriblesScroll.delete(cb);
            }
        }]
}

const useScrollTo = (scrollY = 0) => {
    useEffect(() => {
        _scroller.scrollTo({
            top: scrollY
        })
    }, [])
}


const setScrollY = (scrollY = 0) => {
    _scroller.scrollTo({
        top: scrollY
    })
}


export { useScroll, useScrollEnd, useScrollTo, setScrollY, getScrollY, setAlwaysShowScroll, getPageHeight }
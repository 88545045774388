import './ads-filter.scss';
import { useCallback, useEffect, useMemo, useState } from "react";
import { APP_CONSTANT, APP_STRING } from "../../constant";
import FilterModel from "../../models/filter.model";
import MCDropdown from "../../widgets/dropdown/mc-dropdown";


let _default = {
    format: [],
    feature: [],
    device: [],
    industry: []
}

const ADS_FILTER_CONST = APP_CONSTANT.ADS_FILTER, OPTION_NOTHING_VALUE = '';


const defaultFormat = new FilterModel({ text: APP_STRING.default_dropdown_format, value: OPTION_NOTHING_VALUE }),
    defaultFeature = new FilterModel({ text: APP_STRING.default_dropdown_feature, value: OPTION_NOTHING_VALUE }),
    defaultDevice = new FilterModel({ text: APP_STRING.default_dropdown_device, value: OPTION_NOTHING_VALUE }),
    defaultIndustry = new FilterModel({ text: APP_STRING.default_dropdown_industry, value: OPTION_NOTHING_VALUE });

const isNotFilter = (filterOptions, latestFilter) =>
    (filterOptions.format.length === 0)
    && (filterOptions.feature.length === 0)
    && (filterOptions.device.length === 0)
    && (filterOptions.industry.length === 0);


/**
 * @returns {[filterSelected ,Function]}
 */
const useDropDownBinder = ({
    defaultOption = {},
    onChange = () => { }
}, depends = null) => {

    const [selectedOption, setSelectedOption] = useState(null);

    const onDropdownChange = useCallback((selectedOption) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption);
    }, [onChange, ...depends]);

    const result = useMemo(() => {
        return selectedOption || defaultOption;
    }, [defaultOption, selectedOption]);

    return [result, onDropdownChange, function clearOption() { setSelectedOption(null) }];
}


const IsAllOpionsSelected = filterSelected => {
    return filterSelected.format === OPTION_NOTHING_VALUE
        && filterSelected.feature === OPTION_NOTHING_VALUE
        && filterSelected.industry === OPTION_NOTHING_VALUE;
}


const AdsFilter = ({
    formatFilters = _default.format,
    featureFilters = _default.feature,
    deviceFilters = _default.device,
    industryFilters = _default.industry,
    defaultFormatValue = '',
    defaultFeatureValue = '',
    defaultDeviceValue = '',
    defaultIndustryValue = '',
    showEntries = [ADS_FILTER_CONST.show_all],
    onFilterApplied,
    ...rest }) => {

    const optionFormats = [defaultFormat.toSelectOption(), ...FilterModel.ConvertFiltersToOptions(formatFilters)],
        optionFeature = [defaultFeature.toSelectOption(), ...FilterModel.ConvertFiltersToOptions(featureFilters)],
        optionDevice = [defaultDevice.toSelectOption(), ...FilterModel.ConvertFiltersToOptions(deviceFilters)],
        optionIndustry = [defaultIndustry.toSelectOption(), ...FilterModel.ConvertFiltersToOptions(industryFilters)];

    const [filterSelected, setFilterSelected] = useState({
        format: defaultFormatValue,
        feature: defaultFeatureValue,
        device: defaultDeviceValue,
        industry: defaultIndustryValue,
    });

    const [latestFilter, setLatestFilter] = useState(filterSelected);

    const onSearchClicked = useEffect(() => {
        if (onFilterApplied == null) {
            return;
        }
        const result = Object.keys(filterSelected).reduce((out, key) => {
            if (filterSelected[key] !== OPTION_NOTHING_VALUE) {
                out[key] = filterSelected[key];
            } else {
                out[key] = '';
            }
            return out;
        }, {});
        setLatestFilter(filterSelected);

        onFilterApplied(result);
    }, [filterSelected]);

    const applyFilter = (filterValue) => {
        setFilterSelected(prevValue => ({ ...prevValue, ...filterValue }));
    }

    const [selectedFormatOption, onFormatChange, clearFormatOption] = useDropDownBinder({
        defaultOption: optionFormats.find(i => i.value === filterSelected.format) || defaultFormat.toSelectOption(),
        onChange: selectedOption => applyFilter({ format: selectedOption.value }),
    }, [filterSelected]);

    const [selectedFeatureOption, onFeatureChange, clearFeatureOption] = useDropDownBinder({
        defaultOption: optionFeature.find(i => i.value === filterSelected.feature) || defaultFeature.toSelectOption(),
        onChange: selectedOption => applyFilter({ feature: selectedOption.value }),
    }, [filterSelected]);

    const [selectedDeviceOption, onDeviceChange, clearDeviceOption] = useDropDownBinder({
        defaultOption: optionDevice.find(i => i.value === filterSelected.device) || defaultDevice.toSelectOption(),
        onChange: selectedOption => applyFilter({ device: selectedOption.value }),
    }, [filterSelected]);

    const [selectedIndustryOption, onIndustryChange, clearIndustryOption] = useDropDownBinder({
        defaultOption: optionIndustry.find(i => i.value === filterSelected.industry) || defaultIndustry.toSelectOption(),
        onChange: selectedOption => applyFilter({ industry: selectedOption.value }),
    }, [filterSelected]);

    const resetForm = useCallback(() => {
        clearDeviceOption();
        clearFeatureOption();
        clearFormatOption();
        clearIndustryOption();
    }, [filterSelected]);

    const onClearSearchClicked = useCallback(() => {

        const resetValue = {
            device: OPTION_NOTHING_VALUE,
            format: OPTION_NOTHING_VALUE,
            feature: OPTION_NOTHING_VALUE,
            industry: OPTION_NOTHING_VALUE,
        }
        resetForm();
        setFilterSelected(resetValue);
        setLatestFilter(resetValue);
        onFilterApplied(resetValue);

    });

    let needShowFormat, needShowFeature, needShowDevice, needShowIndustry, templClassName = 'template-';

    if (showEntries.indexOf(ADS_FILTER_CONST.show_all) > -1) {
        needShowFormat = true;
        needShowFeature = true;
        needShowDevice = true;
        needShowIndustry = true;
        templClassName += '4';
    } else {
        needShowFormat = showEntries.indexOf(ADS_FILTER_CONST.show_format) > -1;
        needShowFeature = showEntries.indexOf(ADS_FILTER_CONST.show_feature) > -1;
        needShowDevice = showEntries.indexOf(ADS_FILTER_CONST.show_device) > -1;
        needShowIndustry = showEntries.indexOf(ADS_FILTER_CONST.show_industry) > -1;
        templClassName += showEntries.length;
    }

    return (
        <div className={`wrap wrap-ads-filter ${templClassName}${isNotFilter(filterSelected) ? ' hide-submit' : ''}`}>
            <div className='row'>
                {needShowIndustry && <div className='col'>
                    <span className='lb-title'>
                        {
                            APP_STRING.industry
                        }
                    </span>
                    <MCDropdown
                        className='dropdown-ads-industry'
                        options={optionIndustry}
                        defaultValue={selectedIndustryOption}
                        onChange={onIndustryChange}
                    />
                </div>
                }
                {needShowFormat && <div className='col'>
                    <span className='lb-title'>
                        {
                            APP_STRING.format
                        }
                    </span>
                    <MCDropdown
                        className='dropdown-ads-format'
                        options={optionFormats}
                        defaultValue={selectedFormatOption}
                        onChange={onFormatChange}
                    />
                </div>
                }
                {needShowFeature && <div className='col'>
                    <span className='lb-title'>
                        {
                            APP_STRING.feature
                        }
                    </span>
                    <MCDropdown
                        className='dropdown-ads-feature'
                        options={optionFeature}
                        defaultValue={selectedFeatureOption}
                        onChange={onFeatureChange}
                    />
                </div>
                }
            </div>
            {
                <div className='row row-submit'>
                    <div className='col col-submit'>
                        <button className='btn-banner btn-clear-search' disabled={isNotFilter(latestFilter)} onClick={onClearSearchClicked}>
                            {APP_STRING.button_clear_search}
                        </button>
                    </div>
                </div>
            }
        </div >
    )
}

export default AdsFilter;

export { ADS_FILTER_CONST }
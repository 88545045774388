import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { APP_ROUTE_KEY, APP_STRING } from "../../constant";
import useFetchAds, { clearAdsCaching } from "../../hooks/useFetchAds";
import { useScrollEnd, useScroll, setScrollY, getScrollY, getPageHeight } from "../../hooks/useScroll";
import AppUtils from "../../utils/utils";
import MCLoading from "../../widgets/loading/mc-loading";
import AdsCard from '../ads-card/ads-card';
import './ads-filter-result.scss'


const MOBILE_COL = 2, TABLET_COL = 3, DESKTOP_COL = 4;

const STATUS = {
    nothing: 'nothing',
    loading: 'loading',
    no_result: 'no_result',
    loaded: 'loaded',
}

const __caching = {
    scroll: {},
    latestPage: ''
}

window._scrollCaching = __caching;


const cacheScroll = (page, scrollY = 0) => {

    if (__caching.latestPage !== page && __caching.scroll[__caching.latestPage]) {
        __caching.scroll[__caching.latestPage].scrollY = 0;
        __caching.scroll[__caching.latestPage].hasScrolled = false;
    }
    if (__caching.scroll[page] === undefined) {
        __caching.scroll[page] = {
            scrollY: scrollY,
            hasScrolled: false
        }
        __caching.latestPage = page;
    } else {
        __caching.scroll[page].scrollY = scrollY;
    }
}

const getScrollCaching = page => {
    return __caching.scroll[page];
}

const forceScrollTo = scrollItem => {

    let countLoop = 0;

    const interval = setInterval(() => {
        countLoop++;
        if (countLoop > 50) {
            clearInterval(interval);
        } else if (getScrollY() === scrollItem.scrollY) {
            clearInterval(interval);
        } else if (getScrollY() !== scrollItem.scrollY && getPageHeight() >= scrollItem.scrollY) {
            if (scrollItem.scrollY > 0) {
                setScrollY(scrollItem.scrollY);
            }
            clearInterval(interval);
            scrollItem.hasScrolled = true;
        }
    }, 100);
    return function clear() {
        clearTimeout(interval);
    }
}

const calculateRealImageHeight = ({ naturalWidth = 0, naturalHeight = 0, numberOfCol = 3 }) => {
    const ratio = naturalHeight / naturalWidth;
    return window.innerWidth / numberOfCol * ratio;
}

/**
 * 
 * @param {number} numberOfCol 
 * @param {Function} expectedValue 
 * @returns {[any]}
 */
const prepareCardCols = (numberOfCol, expectedValue) => Array.from(new Array(numberOfCol)).map(item => expectedValue(item));

/**
 * 
 * @param {[number]} colHeights 
 * @param {AdsModel} nextAdsCard 
 */
const canPushNextCardToCol = (colHeights, cardHeight, defaultColIndex, adsCard) => {
    // const BUFFER_HEIGHT = nextAdsCard.thumbSize.height * 0.3;
    if (cardHeight === 0) {
        return defaultColIndex;
    }
    let colIndexCanPush = defaultColIndex,
        latestHeight = colHeights[colIndexCanPush] + cardHeight ;
    for (let i = 0; i < colHeights.length; i++) {
        let nextHeight = colHeights[i] + cardHeight;
        if (nextHeight < latestHeight) {
            colIndexCanPush = i;
            latestHeight = nextHeight;
        }
    }

    return colIndexCanPush;
}


const initArrangeColValue = (numberOfCols) => {
    return {
        lastIndex: 0,
        colHeights: numberOfCols.map((noCol) => prepareCardCols(noCol, () => 0)),
        adsArrangeCol: numberOfCols.map((noCol) => prepareCardCols(noCol, () => [])),
        adsIdRendered: new Set()
    }
}

/**
 * 
 * @param {[number]} numberOfCols 
 * @param {[AdsModel]} adsCards 
 */
const useArrangeCol = (numberOfCols, adsCards, cb) => {

    const [data, setData] = useState((initArrangeColValue(numberOfCols)));

    const results = useMemo(() => {
        if (adsCards.length === 0) {
            cb(false);
            return data.adsArrangeCol;
        }

        let cloneAds = AppUtils.CloneArray(adsCards),
            cloneData = { ...data };

        cloneData.adsArrangeCol = cloneAds.reduce((output, adsCard, idx) => {
            let index = cloneData.lastIndex + idx;

            if (cloneData.adsIdRendered.has(adsCard.id)) {
                return output;
            }
            cloneData.adsIdRendered.add(adsCard.id);
            for (let i = 0; i < numberOfCols.length; i++) {
                let targetCol = numberOfCols[i];
                let colIndex = index % targetCol;

                const cardHeight = calculateRealImageHeight({
                    naturalHeight: adsCard.thumbSize.height,
                    naturalWidth: adsCard.thumbSize.width,
                    numberOfCol: targetCol.length
                });

                colIndex = canPushNextCardToCol(cloneData.colHeights[i], cardHeight, colIndex, adsCard);

                output[i][colIndex].push(adsCard);

                cloneData.colHeights[i][colIndex] += cardHeight;

            }
            return output;
        }, cloneData.adsArrangeCol);

        cloneData.lastIndex = cloneData.lastIndex + adsCards.length;
        cb(cloneData.colHeights[0] && cloneData.colHeights[0][0] > 0);
        setData(cloneData);
        return cloneData.adsArrangeCol;
    }, [adsCards, data, numberOfCols, cb]);

    const hasExistedData = results && results[0][0] && results[0][0].length > 0;

    return [results, hasExistedData, function reset() {
        setData(initArrangeColValue(numberOfCols))
    }];
}

const AdsFilterResult = ({
    page = 'digital-ads',
    format = 'default',
    feature = 'default',
    device = 'default',
    industry = 'default',
    autoCache = true,
    onResultsChange = ({ results, filterKeys = { format: '', feature: '', device: '', industry: '' } }) => { }
}) => {

    const [loadStatus, setLoadStatus] = useState({ hasLoadData: false, status: STATUS.nothing })

    const [nextAdsCards, getNextPage, cleanChunkAdsCards, deleteCaching] = useFetchAds({
        page, format, feature, device, industry, autoCache
    }, function onBegin() {
        setLoadStatus(prev => {
            return { hasLoadData: false, status: STATUS.loading };
        })
    }, function onDone() {
        setLoadStatus(prev => {
            return { ...prev, hasLoadData: true }
        });
    });

    useScroll(function onScrolling(scrollY) {
        // Only capture scroll when existed Ads list;
        if (hasExistedData) {
            cacheScroll(page, scrollY);
        }
    });

    useScrollEnd(function onScrollToEnd() {
        if (loadStatus.status === STATUS.loading) {
            return;
        }

        if (typeof getNextPage === 'function' && cardsColsDesktop[0].length > 0) {
            getNextPage();
        }
    }, [getNextPage, loadStatus]);

    // Split card to 3 cols
    const [[cardsColsDesktop, cardsColsTablet, cardColsMobile], hasExistedData, resetCardCols] = useArrangeCol([DESKTOP_COL, TABLET_COL, MOBILE_COL], nextAdsCards, function loadDone() {
        if (nextAdsCards.length > 0) {
            cleanChunkAdsCards();
        }
    });

    useEffect(function clearCardsColumnWhenChangeFilter() {
        resetCardCols();
        return function clearCaching() {
            // Delete Ads cache if not move to detail page
            const isMoveToDetailPage = APP_ROUTE_KEY.ads_detail.filter(detailPagePath => window.location.href.indexOf(detailPagePath) > -1).length > 0;
            if (!isMoveToDetailPage) {
                clearAdsCaching({ page, format, feature, device, industry });
            }
        }
    }, [page, format, feature, device, industry]);

    useMemo(() => {
        onResultsChange({
            filterKeys: { format, device, feature, industry },
            results: cardsColsDesktop.reduce((out, next) => { out.push(...next); return out; }, [])
        });
    }, [cardsColsDesktop, onResultsChange, format, device, feature, industry]);


    useLayoutEffect(function initScrollCaching() {
        // cacheScroll(page, 0);
        const existedScrollItem = getScrollCaching(page);
        if (existedScrollItem === undefined) {
            cacheScroll(page, 0);
        } else {
            cacheScroll(page, existedScrollItem.scrollY);
        }
        return () => {
            const item = getScrollCaching(page);
            if (item) {
                item.hasScrolled = false;
            }
        }
    }, []);

    useLayoutEffect(function restoreScroll() {
        const scrollItem = getScrollCaching(page);
        let stopScroll;
        if (scrollItem && scrollItem.hasScrolled === false) {
            stopScroll = forceScrollTo(scrollItem);
        }
        return () => {
            if (stopScroll)
                stopScroll();
        }
    }, [cardsColsDesktop])


    useEffect(() => {

        const hasFetchedData = () => {
            return (
                !loadStatus.hasLoadData
                || (loadStatus.hasLoadData && (loadStatus.status === STATUS.no_result || loadStatus.status === STATUS.loaded))
            )
        }

        const isNoResult = () => {
            return nextAdsCards.length === 0 && cardsColsDesktop[0].length === 0;
        }

        const timeout = setTimeout(() => {
            if (hasFetchedData(loadStatus)) {
                return;
            }
            if (isNoResult()) {
                setLoadStatus(prev => {
                    return { ...prev, status: STATUS.no_result }
                });
            } else {
                setLoadStatus(prev => {
                    return { ...prev, status: STATUS.loaded }
                });
            }
        }, 200);
        return () => clearTimeout(timeout);
    }, [nextAdsCards, cardsColsDesktop, loadStatus]);


    return (
        <div className='rs-holder'>
            {
                (loadStatus.status === STATUS.no_result && loadStatus.hasLoadData) && <NoResult />
            }
            {
                (loadStatus.status !== STATUS.no_result)
                && <div className={`mc-ads-result ${loadStatus.status === STATUS.loading ? 'is-loading' : ''}`}>
                    <div className='m-four-cols'>{
                        cardsColsDesktop.map((cardCol, colIndex) =>
                            <div key={`card-col-d-${colIndex}`} className={`ads-card-col card-col-${colIndex}`}>
                                {
                                    cardCol.map((card, index) =>
                                        <AdsCard
                                            key={`d-${card.id}`}
                                            canShow={card.canShow}
                                            adsModel={card}
                                        />)
                                }
                            </div>
                        )
                    }
                    </div>
                    <div className='m-three-cols'>{
                        cardsColsTablet.map((cardCol, colIndex) =>
                            <div key={`card-col-d-${colIndex}`} className={`ads-card-col card-col-${colIndex}`}>
                                {
                                    cardCol.map((card, index) =>
                                        <AdsCard
                                            key={`d-${card.id}`}
                                            canShow={card.canShow}
                                            adsModel={card}
                                        />)
                                }
                            </div>
                        )
                    }
                    </div>
                    <div className='m-two-cols'>
                        {
                            cardColsMobile.map((cardCol, colIndex) =>
                                <div key={`card-col-m-${colIndex}`} className={`ads-card-col card-col-${colIndex}`}>
                                    {cardCol.map((card, index) =>
                                        <AdsCard
                                            key={`m-${card.id}`}
                                            canShow={card.canShow}
                                            adsModel={card}
                                        />)
                                    }
                                </div>
                            )
                        }
                    </div>
                    {
                        (loadStatus.status === STATUS.loading || !loadStatus.hasLoadData) && <MCLoading />
                    }
                </div>
            }
        </div>
    )
}

const NoResult = () => {
    return (
        <>
            <section className='section-top --no-result'>
                <div className='row'>
                    <span className='lb-page-name'>
                        {APP_STRING.filter_no_result_title}
                    </span>
                </div>
                <div className='row'>
                    {APP_STRING.filter_no_result_message}
                </div>
            </section>
        </>
    )
}

const areEqual = (prevProps, nextProps) => {
    return prevProps.format === nextProps.format
        && prevProps.feature === nextProps.feature
        && prevProps.device === nextProps.device
        && prevProps.industry === nextProps.industry
        && prevProps.getFilter === nextProps.getFilter
        && prevProps.onResultsChange === nextProps.onResultsChange
}

export default memo(AdsFilterResult, areEqual);
import { combineReducers } from "@reduxjs/toolkit";

const reducers = [

]

const _getReducer = () => {
    return combineReducers(reducers);
}

export { _getReducer }
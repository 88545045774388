
import faker from 'faker';

let id = 0;
const BaseModel = function () {
    this.id = id++;
    this.isDisabled = false;
    this.isDeleted = false;
    this.createdAt = new Date().getTime();
    this.modifiedAt = new Date().getTime();
}

BaseModel.prototype.random = function () {
    return this;
}

BaseModel.prototype.getFaker = function () {
    return faker;
}

BaseModel.prototype.clone = function () {

}


export default BaseModel;

import { APP_COLOR, APP_CONSTANT, APP_STRING } from "../../../constant"
import useSetClassRoot from "../../../hooks/useSetClassRoot";
import AdsFilterBasePage from "../base-ads.page";

const DigitalAdsPage = () => {

    useSetClassRoot({ className: 'page-digital-ads' });

    return (
        <AdsFilterBasePage
            pageName={APP_STRING.page_digital_ads_name}
            mainColor={APP_COLOR.digital_ads}
            showFilterEntries={[APP_CONSTANT.ADS_FILTER.show_all]}
        />
    )
}

export default DigitalAdsPage;
import { AdsBannerModel } from ".";
import { APP_CONSTANT } from "../constant";
import AppUtils from "../utils/utils";
import BaseModel from "./base.model";



class AdsModel extends BaseModel {
    static get ADS_TYPE() {
        return APP_CONSTANT.ADS_TYPE;
    }


    client = {
        name: '',
    };
    projectName = '';
    thumbnail = '';
    thumbSize = {
        width: 0,
        height: 0
    }
    banner = '';
    adsType = APP_CONSTANT.ADS_TYPE.video;
    type = '';
    sliders = [];
    formats = [];
    features = [];
    industries = [];
    devices = [];
    background = '';
    hasFetchDetail = false;
    canShow = false;
    backgroundOrIdea = 'Character count for Background/Idea maximum: 200 - 220 including spaces.'
    url = '';
    creative_link = '';
    spec_link = '';
    page_name = ''
    sharing = {
        mail: '',
        whatsapp: ''
    }

    get hasLoadThumb() {
        return this.thumbSize.height > 0;
    }

    /**
     * @return Promise<AdsModel>
     */
    fetchAdsDetail() {
        this.hasFetchDetail = true;

        return Promise.resolve(this);
    }

    random() {
        if (this.getFaker() === null) {
            return this;
        }
        this.thumbnail = this.getFaker().image.image();

        // this.thumbnail = `https://picsum.photos/${240}/${AppUtils.RandomInteger(180, 320)}`;
        this.thumbnail = `https://loremflickr.com/${240}/${AppUtils.RandomInteger(180, 320)}`;
        // this.thumbnail = this.thumbnail.replace('/480/', `/${AppUtils.RandomInteger(240, 1080)}/`) + '?timestamp=' + performance.now();

        this.banner = `https://loremflickr.com/${AppUtils.RandomInteger(720, 1600)}/${AppUtils.RandomInteger(320, 600)}`;

        this.client.name = this.getFaker().company.companyName();

        this.projectName = this.getFaker().name.title();

        this.features = [this.getFaker().company.companyName()];

        this.devices = [this.getFaker().company.companyName()];

        this.formats = [this.getFaker().company.companyName()];

        this.industries = [this.getFaker().company.companyName()];

        this.client.name = this.getFaker().company.companyName();

        const randomNum = AppUtils.RandomInteger(0, 20);
        if (randomNum % 4 === 0) {
            this.adsType = APP_CONSTANT.ADS_TYPE.podcast;
        } else if (randomNum % 3 === 0) {
            this.adsType = APP_CONSTANT.ADS_TYPE.video;
        } else {
            this.adsType = APP_CONSTANT.ADS_TYPE.image;
        }

        return this;
    }

    /**
    * @returns {string}
    */
    toClientString() {
        return this.client.name;
    }

    /**
    * @returns {string}
    */
    toProjectString() {
        return this.projectName;
    }

    /**
  * @returns {string}
  */
    toBackgroundIdeaString() {
        return this.backgroundOrIdea;
    }

    /**
     * @returns {string}
     */
    toFeatureString() {
        return this.features.join(',');
    }

    /**
   * @returns {string}
   */
    toFormatString() {
        return this.formats.join(',');
    }

    /**
       * @returns {string}
       */
    toDeviceString() {
        return this.devices.join(',');
    }

    /**
   * @returns {string}
   */
    toIndustryString() {
        return this.industries.join(',');
    }

    fromJson(obj) {
        this.id = obj.id;
        this.thumbnail = obj.img_url;
        this.client.name = obj.client || '';
        this.projectName = obj.project || '';
        this.formats = [obj.format || ''];
        switch (obj.icon) {
            case 'video':
                this.adsType = APP_CONSTANT.ADS_TYPE.video;
                break;
            case 'podcast':
                this.adsType = APP_CONSTANT.ADS_TYPE.podcast;
                break;
            default:
                this.adsType = APP_CONSTANT.ADS_TYPE.default;
                break;
        }
        return this;
    }

    fromDetailJson(obj) {
        this.id = obj.id;
        this.client.name = obj.client || '';
        this.projectName = obj.project || '';
        this.backgroundOrIdea = obj.idea || '';
        this.creative_link = obj.creative_link || '';
        this.spec_link = obj.spec_link || '';
        this.type = obj.type || '';
        this.page_name = obj.category || '';

        this.features = [obj.options.Feature];
        this.devices = [obj.options.Device];
        this.industries = [obj.options.Industry];
        this.formats = [obj.options.Format];

        switch (obj.icon) {
            case 'video':
                this.adsType = APP_CONSTANT.ADS_TYPE.video;
                break;
            case 'podcast':
                this.adsType = APP_CONSTANT.ADS_TYPE.podcast;
                break;
            default:
                this.adsType = APP_CONSTANT.ADS_TYPE.default;
                break;
        }
        if (Array.isArray(obj.metadata)) {
            this.sliders = obj.metadata.map(item => new AdsBannerModel().fromJson(item));
        } else {
            this.sliders = [new AdsBannerModel().fromJson(obj.metadata)];
        }
        return this;
    }

    clone() {
        let clone = new AdsModel();
        for (let i in this) {
            clone[i] = this[i];
        }
        return clone;
    }

    toMailSharing() {

        let body = '';

        const toLabel = input => input + APP_CONSTANT.MAIL_NEW_LINE;
        const toValue = input => input + APP_CONSTANT.MAIL_NEW_LINE + APP_CONSTANT.MAIL_NEW_LINE;

        // body += toLabel(APP_STRING.detail_label_client);
        // body += toValue(this.toClientString())

        // if (this.toDeviceString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_device);
        //     body += toValue(this.toDeviceString())
        // }

        // if (this.toFormatString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_format);
        //     body += toValue(this.toFormatString());
        // }

        // if (this.toFeatureString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_features);
        //     body += toValue(this.toFeatureString());
        // }

        // if (this.toIndustryString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_industry);
        //     body += toValue(this.toIndustryString());
        // }

        body += toLabel(this.toClientString());
        body += toValue(this.toBackgroundIdeaString());

        return {
            title: this.toProjectString(),
            body: body,
            url: window.location.href
        }
    }

    toWhatsappSharing() {

        let body = '';

        const toLabel = input => input + APP_CONSTANT.WHATAPP_NEW_LINE;
        const toValue = input => input + APP_CONSTANT.WHATAPP_NEW_LINE + APP_CONSTANT.WHATAPP_NEW_LINE;

        // body += toLabel(APP_STRING.detail_label_client);
        // body += toValue(this.toClientString())

        // if (this.toDeviceString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_device);
        //     body += toValue(this.toDeviceString())
        // }

        // if (this.toFormatString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_format);
        //     body += toValue(this.toFormatString());
        // }

        // if (this.toFeatureString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_features);
        //     body += toValue(this.toFeatureString());
        // }

        // if (this.toIndustryString().length > 0) {
        //     body += toLabel(APP_STRING.detail_label_industry);
        //     body += toValue(this.toIndustryString());
        // }

        body += toLabel(this.toClientString());
        body += toValue(this.toBackgroundIdeaString());

        return {
            title: this.toProjectString(),
            body: body,
            url: window.location.href
        }
    }

    isBannerImageType() {
        return this.sliders.length > 0 && this.sliders[0].type === APP_CONSTANT.ADS_SLIDER_TYPE.image;
    }
}

export default AdsModel;

// import './videojs-7.10.2-lib.min.js';
// import videojs from 'video.js';

import { loadScript } from "../utils";

window.VIDEOJS_NO_DYNAMIC_STYLE = true;


const ATTR_KEYS = {
    VIDEO_URL: 'data-video-url',
    AUTO_PLAY: 'data-auto-play',
    PAUSED: 'data-paused',
    POSTER: 'data-poster'
}

const libs = [
    'https://vjs.zencdn.net/7.10.2/video-js.css',
    'https://vjs.zencdn.net/7.10.2/video.min.js'
]

class MCPlayer extends HTMLElement {

    static get observedAttributes() {
        return Object.values(ATTR_KEYS);
    }

    constructor() {
        super();
        this.player = null;
        this.meta = {
            videoId: '',
            autoPlay: false,
            isMute: true,
            paused: false,
            hasRendered: false,
            poster: ''
        }

        this.callbacks = {
            onVideoPlayed: null
        }
        this.render = this.render.bind(this);
        this.initData();
    }

    initData() {
        this.meta.videoId = this.getAttribute('data-video-url') || '';
    }

    render() {

        const elemVideo = window.document.createElement("video");
        elemVideo.controls = true;
        elemVideo.preload = 'auto';
        elemVideo.classList.add('video-js');
        elemVideo.setAttribute('playsinline', 'playsinline');
        elemVideo.poster = this.meta.poster;
        elemVideo.playsinline = true;
        elemVideo.autoplay = this.meta.autoPlay;
        this.appendChild(elemVideo);

        if (window.videojs != undefined) {
            this.player = window.videojs(elemVideo);
            this.player.src({ type: 'video/mp4', src: this.meta.videoId });
        } else {
            this.player = elemVideo;
            elemVideo.innerHTML = `<source src="${this.meta.videoId}" type="video/mp4" />`;
        }
    }

    connectedCallback() {
        if (!this.meta.hasRendered) {
            this.meta.hasRendered = true;
            loadScript(libs).then(() => {
                this.render();
            })
            // loadVideoJSScript(() => {
            //     this.render();
            // })
        }
    }

    /**
     * 
     * @param {string} videoSrc 
     */
    setVideoSrc(videoSrc) {
        if (videoSrc !== this.meta.videoId) {
            this.meta.videoId = videoSrc;
            if (this.player !== null) {
                this.player.src({ type: 'video/mp4', src: videoSrc });
            }
        }
    }

    setPauseVideo(flag) {
        this.meta.paused = flag

        if (flag && this.player !== null) {
            this.player.pause();
        } else if (!flag && this.player !== null) {
            this.player.play();
        }
    }

    setPoster(poster) {
        this.meta.poster = poster;
        if (this.player !== null) {
            this.player.poster = poster;
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case ATTR_KEYS.VIDEO_URL:
                this.setVideoSrc(newValue);
                break;
            case ATTR_KEYS.PAUSED:
                this.setPauseVideo(Boolean(newValue));
                break;
            case ATTR_KEYS.POSTER:
                this.setPoster(newValue);
                break;
            default:
                break;
        }

    }

    onVideoPlayed(cb) {
        this.callbacks.onVideoPlayed = cb;
    }

}

window.customElements.define('mc-player', MCPlayer);




import AppUtils from '../../utils/utils';
import { loadScript } from '../utils';


const ATTR_KEYS = {
    images: 'data-images'
}

const libs = [
    'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.3/photoswipe.min.css',
    'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.3/default-skin/default-skin.min.css',
    'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.3/photoswipe.min.js',
    'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.3/photoswipe-ui-default.min.js',

]

class MCImageViewer extends HTMLElement {

    static get observedAttributes() {
        return Object.values(ATTR_KEYS);
    }

    constructor() {
        super();
        this.meta = {
            hasRendered: false,
            data: {
                index: 0,
                images: []
            }
        }
    }

    connectedCallback() {
        if (!this.meta.hasRendered) {
            this.classList.add('anim-scale-in')
            loadScript(libs).then(() => {
                this.meta.hasRendered = true;
                this.render();
                this.setImages(this.meta.data);
            })

        }
    }

    setImages({ index = 0, images = [] }) {
        if (this.querySelector('.pswp') === undefined || !this.meta.hasRendered) {
            this.meta.data.index = index;
            this.meta.data.images = images;
            return;
        }
        if (this._gallery) {
            this._gallery.destroy();
        }

        const sizes = images.map(item => {
            return AppUtils
                .LoadImageSize(item.src)
                .then(({ naturalWidth, naturalHeight }) => {
                    item.w = naturalWidth;
                    item.h = naturalHeight;
                    return Promise.resolve(item);
                })
        })


        Promise.all(sizes).then(() => {

            var options = {
                index: index,
                escKey: false,
                history: true
            };

            // Initializes and opens PhotoSwipe

            var gallery = new window.PhotoSwipe(this.querySelector('.pswp'), window.PhotoSwipeUI_Default, images, options);
            gallery.init();

            gallery.listen('close', () => {
                this.destroy();
            });

            gallery.listen('destroy', () => {
                this.destroy();
            });
            this._gallery = gallery;
        })
    }

    destroy() {
        setTimeout(() => {
            if (!!this.parentNode) {
                this.parentNode.removeChild(this);
            }
        }, 50)
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case ATTR_KEYS.images:
                this.setImages(JSON.parse(newValue));

                break;
            default:
                break;
        }
    }

    render() {
        this.innerHTML = `   
        <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="pswp__bg"></div>
            <div class="pswp__scroll-wrap">
                <div class="pswp__container">
                    <div class="pswp__item"></div>
                    <div class="pswp__item"></div>
                    <div class="pswp__item"></div>
                </div>
                <div class="pswp__ui pswp__ui--hidden">
                    <div class="pswp__top-bar">
                        <div class="pswp__counter"></div>     
                        <button class="pswp__button pswp__button--close" title="Close"></button>
                        <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
                        <div class="pswp__preloader">
                            <div class="pswp__preloader__icn">
                              <div class="pswp__preloader__cut">
                                <div class="pswp__preloader__donut"></div>
                              </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                        <div class="pswp__share-tooltip"></div> 
                    </div>
        
                    <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                    </button>
        
                    <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                    </button>
    
                    <div class="pswp__caption">
                        <div class="pswp__caption__center"></div>
                    </div>
                </div>
            </div>
        </div>`
    }
}

window.customElements.define('mc-image-viewer', MCImageViewer);

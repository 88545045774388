
import './contact.page.scss';
import MCPage from "../../widgets/mc-page/mc-page";
import useSetClassRoot from "../../hooks/useSetClassRoot";
import { APP_CONSTANT, APP_STRING } from '../../constant';
import MCInput from '../../widgets/input/mc-input';
import MCTextArea from '../../widgets/textarea/mc-textarea';
import AppFooter from '../../components/footer/footer';
import { useScrollTo } from '../../hooks/useScroll';
import MCCheckbox from '../../widgets/checkbox/mc-checkbox';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ContactModel } from '../../models/contact.model';
import ApiHelper from '../../api/api';
import showFullLoading from '../../hooks/useFullLoading';
import AppUtils from '../../utils/utils';
import MCDescription from '../../widgets/description/mc-description';
import { useHistory } from 'react-router-dom';


const INPUT_KEY = {
    name: 'name',
    phone: 'phone',
    mail: 'mail',
    message: 'message'
}

const useGCatcha = () => {


    const getToken = useCallback(() => {
        return new Promise(next => {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(APP_CONSTANT.GCATCHA_KEY, { action: 'submit' }).then(function (token) {
                    next(token);
                });
            });
        })

    })

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${APP_CONSTANT.GCATCHA_KEY}`;
        script.type = 'text/javascript';
        document.body.appendChild(script);
        script.onload = () => {

        }
        return () => {
            script.parentNode.removeChild(script);
            //Remove catcha UI
            const badges = document.querySelectorAll('.grecaptcha-badge');
            badges.forEach(elem => {
                const parent = elem.parentNode;
                parent.parentNode.removeChild(parent);
            })
        }
    }, [])
    return getToken;
}

const ContactPage = () => {

    useSetClassRoot({ className: 'page-contact' });
    useScrollTo(0);
    const [showThankYou, setShowThankYou] = useState(false);
    const refCatcha = useRef();
    const [contactModel, setContactModel] = useState(new ContactModel());
    const [[contentLeft, contentLink, contentRight], setPrivacyContent] = useState(['', '', '']);
    const [validateResult, setValidateResult] = useState(contactModel.toValidate());

    const onPrivacyChecked = useCallback((e) => {
        const target = e.currentTarget || e.target;
        contactModel.allow_privacy = target.checked;
        setContactModel(contactModel.clone());
    });

    const getGcaptchaToken = useGCatcha();


    useEffect(function splitPrivacyContent() {
        const texts = APP_STRING.consent_privacy.split("##PRIVACY_REPLACEMENT##");

        const joinedText = ['', '', ''];
        if (texts.length === 2) {
            joinedText[0] = texts[0];
            joinedText[1] = APP_STRING.consent_privacy_replacement;
            joinedText[2] = texts[1]
        }
        setPrivacyContent(joinedText);
    }, []);

    const history = useHistory();


    const onRegisterClicked = useCallback((e) => {
        const validateResult = contactModel.validate();
        setValidateResult(validateResult);
        if (validateResult.isError === false) {
            showFullLoading({ isShow: true, background: 'rgba(54, 54, 54, 0.5)' });
            getGcaptchaToken().then(token => {
                ApiHelper
                    .NewContact(contactModel)
                    .then(() => {
                        history.push('/thank-you');
                    }).finally(() => {
                        showFullLoading({ isShow: false });
                    });
            })
        }
    });

    const onTextChanges = useCallback((value, key) => {
        switch (key) {
            case INPUT_KEY.name:
                contactModel.name = value;
                break;
            case INPUT_KEY.phone:
                contactModel.phone = value;
                break;
            case INPUT_KEY.mail:
                contactModel.email = value;
                break;
            case INPUT_KEY.message:
                contactModel.content = value;
                break;
        }
        setContactModel(contactModel.clone());
    }, []);

    return (
        <MCPage>
            {!showThankYou && <>
                <section className='section-top'>
                    <div className='row'>
                        <span className='lb-page-name'>
                            {APP_STRING.page_contact_name}
                        </span>
                    </div>
                    <div className='row'>
                        <span className='lb-page-description'>
                            <MCDescription content={APP_STRING.contact_description} />
                        </span>
                    </div>
                </section>
                <section className={`section-form${AppUtils.IsIOS() ? ' is-ios' : ''}`}>
                    <div class='zoom-holder'>
                        <div className='scale-holder'>
                            <div class='row'>
                                <MCInput placeHolder={APP_STRING.contact_form_name}
                                    onTextChange={onTextChanges}
                                    errorMsg={validateResult.name}
                                    inputKey={INPUT_KEY.name} />
                            </div>
                            <div class='row'>
                                <MCInput placeHolder={APP_STRING.contact_form_email}
                                    onTextChange={onTextChanges}
                                    errorMsg={validateResult.email}
                                    inputKey={INPUT_KEY.mail} />
                            </div>
                            <div class='row'>
                                <MCInput placeHolder={APP_STRING.contact_form_phone}
                                    onTextChange={onTextChanges}
                                    errorMsg={validateResult.phone}
                                    inputKey={INPUT_KEY.phone} />
                            </div>
                            <div class='row'>
                                <MCTextArea placeHolder={APP_STRING.contact_form_message}
                                    errorMsg={validateResult.content}
                                    onTextChange={onTextChanges}
                                    inputKey={INPUT_KEY.message} />
                            </div>
                        </div>
                    </div>
                    <div className='row row-consent-privacy'>
                        <div className={`privacy-wrapper` + (contactModel.allow_privacy === false && validateResult.privacy.length > 0 ? ' error' : '')}>
                            <MCCheckbox text="Checkbox here" onChange={onPrivacyChecked} />
                            <span className='ct-privacy'>
                                {contentLeft}
                                <a href={APP_STRING.consent_privacy_url} target='_blank' >
                                    {contentLink}
                                </a>
                                {contentRight}
                            </span>
                        </div>
                    </div>
                    <div className='wrap-gcatcha' ref={refCatcha}></div>
                    <div class='row row-submit'>
                        <button className='btn-white' onClick={onRegisterClicked}>
                            {APP_STRING.contact_form_submit}
                        </button>
                    </div>
                </section>
            </>
            }
            {
                showThankYou && <ThankYouSection />
            }
            <AppFooter showEntries={[APP_CONSTANT.footer_show_logo, APP_CONSTANT.footer_show_contact]} />
        </MCPage >
    )
}


const ThankYouSection = () => {
    const thankYou = APP_STRING.thank_you;
    return (
        <>
            <section className='section-top --thank-you'>
                <div className='row'>
                    <span className='lb-page-name'>
                        {APP_STRING.page_contact_thank_you}
                    </span>
                </div>
                <div className='row'>
                    {
                        thankYou.map(str => <p>{str}</p>)
                    }
                </div>
            </section>
        </>
    )
}

export default ContactPage;
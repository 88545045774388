import './loading.scss';

class AppLoading extends HTMLElement {

    constructor() {
        super();
        this.meta = {
            hasRendered: false
        }
    }

    render() {
        this.classList.add('mc-loading');
        this.innerHTML = `<div class='loading-items'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;
    }

    connectedCallback() {
        if (!this.meta.hasRendered) {
            this.meta.hasRendered = true;
            this.render();
        }
    }
}

window.customElements.define('mc-loading', AppLoading);





// const APP_ROUTE_KEY = {
//     home: "/",
//     digital_ads: "/digital-ads",
//     website: "/website",
//     content: '/content',
//     bloomr: '/bloomr',
//     contact: '/contact',
//     ads_detail: 'ads-detail/:id'
// }

// if (true) {

//     APP_ROUTE_KEY.home = '/';
//     APP_ROUTE_KEY.digital_ads = '/digital-ads.html';
//     APP_ROUTE_KEY.website = '/website.html';
//     APP_ROUTE_KEY.content = '/content.html';
//     APP_ROUTE_KEY.bloomr = '/bloomr.html';
//     APP_ROUTE_KEY.contact = '/contact.html';
//     APP_ROUTE_KEY.ads_detail = '/ads-detail.html?id=:id';

// }


const APP_ROUTE_KEY = {
    home: ["/", "/index", "/index.html"],
    digital_ads: ["/digital-ads", "/digital-ads.html"],
    website: ["/websites", "/websites.html"],
    content: ["https://www.mediacorp.sg/en/brandstudio", "https://www.mediacorp.sg/en/brandstudio"],
    bloomr: ["https://bloomr.sg", "https://bloomr.sg"],
    contact: ["/contact", "/contact.html"],
    ads_detail: ["/ads-detail", "/ads-detail.html"],
    thank_you: ["/thank-you", "/thank-you.html"]
}

// const isShowDotHTML = process.env.REACT_APP_USING_DOT_HTML;

// const APP_ROUTE_KEY_HTML = {
//     home: ["/", "/index.html", "/index"],
//     digital_ads: ["/digital-ads.html", "/digital-ads"],
//     website: ["/websites.html", "/websites"],
//     content: ["/content.html", "/content"],
//     bloomr: ["/bloomr.html", "/bloomr"],
//     contact: ["/contact.html", "/contact"],
//     ads_detail: ["/ads-detail.html", "/ads-detail"]
// }

// export default isShowDotHTML ? APP_ROUTE_KEY_HTML : APP_ROUTE_KEY;
export default APP_ROUTE_KEY;
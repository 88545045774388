import { useEffect, useState } from "react"
import ApiHelper from "../api/api";
import AppUtils from "../utils/utils";


const _caching = new Map();

const filterOptionToStoreKey = (filter) => Object.values(filter).filter(i => i.length > 0).join('.').trim();

const saveCache = (storeKey, results, nextPage) => {
    let saveItem;
    if (!_caching.has(storeKey)) {
        saveItem = {
            results: results,
            nextPage: nextPage
        }
    } else {
        saveItem = getCache(storeKey);
        saveItem.results.push(...results);
        saveItem.nextPage = nextPage;
    }
    _caching.set(storeKey, saveItem);
}

const deleteCache = (storeKey) => {
    _caching.delete(storeKey);
}

const getCache = (storeKey) => {
    if (!_caching.has(storeKey)) {
        return null;
    } else {
        return _caching.get(storeKey);
    }
}

const hasCached = (storeKey) => _caching.has(storeKey);

const preLoadThumbnail = (adsCards) => {
    const promises = adsCards.map(ads => {
        if (ads.thumbSize.height === 0) {
            return AppUtils.LoadImageSize(ads.thumbnail);
        } else {
            return Promise.resolve({
                naturalWidth: ads.thumbSize.width,
                naturalHeight: ads.thumbSize.height
            })
        }
    });
    return Promise.all(promises).then(sizes => {
        let i;
        for (i = 0; i < sizes.length; i++) {
            adsCards[i].thumbSize.width = sizes[i].naturalWidth;
            adsCards[i].thumbSize.height = sizes[i].naturalHeight;
        }
        return Promise.resolve(adsCards);
    })
}

const useFetchAds = ({ page = 'Digital-ads', format = '', feature = '', device = '', industry = '', autoCache = true }, onBegin = () => { }, onDone = () => { }) => {
    const storeKey = filterOptionToStoreKey({ page, format, feature, device, industry });

    let temp = getCache(storeKey);

    const [{ nextAdsCards, adsNextPage }, setAdsCardData] = useState({
        nextAdsCards: temp ? temp.results : [],
        adsNextPage: temp ? temp.nextPage : null
    })

    useEffect(() => {
        onBegin();
        if (!hasCached(storeKey)) {
            ApiHelper.FindAdsCardByFilter({
                page, format, feature, device, industry
            }).then(({ results, nextPage }) => {
                if (autoCache) {
                    saveCache(storeKey, results, nextPage);
                }
                return Promise.resolve([results, nextPage])
            }).then(([adsCards, nextPage]) => {
                if (adsCards.length > 0) {
                    return preLoadThumbnail(adsCards)
                        .then(cardLoaded => {
                            return Promise.resolve([cardLoaded, nextPage])
                        })
                } else {
                    return Promise.resolve([adsCards, nextPage]);
                }
            }).then(([adsCards, nextPage]) => {
                setAdsCardData({
                    nextAdsCards: adsCards,
                    adsNextPage: nextPage
                });
            }).finally(() => {
                onDone();
            })
        } else {
            const { results, nextPage } = getCache(storeKey);
            if (results !== nextAdsCards) {
                setAdsCardData({
                    nextAdsCards: results,
                    adsNextPage: nextPage
                });
                onDone();
            }
        }
    }, [page, format, feature, device, industry]);

    return [
        nextAdsCards,
        function getNextPage() {
            if (typeof adsNextPage !== 'function') {
                return;
            }
            onBegin();
            adsNextPage()
                .then(({ results, nextPage }) => {
                    if (results.length > 0) {
                        if (autoCache) {
                            saveCache(storeKey, results, nextPage);
                        }
                        preLoadThumbnail(results)
                            .then(cardLoaded => {
                                onDone();
                                setAdsCardData({
                                    nextAdsCards: cardLoaded,
                                    adsNextPage: nextPage
                                });
                            })

                    } else {
                        onDone();
                        setAdsCardData(prev => {
                            return { ...prev, adsNextPage: null };
                        });
                    }
                })
        },
        function cleanCurrentAdsItems() {
            setAdsCardData(prev => {
                return { ...prev, nextAdsCards: [] };
            });
        },
        function deleteCaching() {
            _caching.delete(storeKey);
        }
    ];
}

const clearAdsCaching = ({ page = 'digital-ads', format = '', feature = '', device = '', industry = '', autoCache = true }) => {
    const storeKey = filterOptionToStoreKey({ page, format, feature, device, industry });
    deleteCache(storeKey);
}

export default useFetchAds;
export { useFetchAds, clearAdsCaching };


import { useEffect, useState } from "react";
import ApiHelper from "../api/api";

/**
 * @returns {AdsModel}
 */
const useFetchAdsDetail = ({ adsId = 0 }, onDone = () => { }) => {
    const [adsDetail, setAdsDetail] = useState();


    useEffect(() => {
        ApiHelper.GetAdsDetailById(adsId)
            .then(setAdsDetail)
            .catch((error) => {
                setAdsDetail('not-found');
            })
        onDone();
    }, [adsId]);
    return adsDetail;
}

export default useFetchAdsDetail;
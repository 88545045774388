import { useCallback, useState } from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom"
import { APP_COLOR, APP_IMAGE, APP_ROUTE_KEY } from "../../constant";
import APP_STRING from "../../constant/strings";
import AdsLinkingCTA from "../ads-linking-cta/ads-linking-cta";
import './navigation-bar.scss';


const MobileNavigation = ({ onClose }) => {

    const closeMenu = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <section className='section-ads-nav mobile-nav' >
            <button className='btn-close-mobile-menu' onClick={closeMenu}></button>
            <AdsLinkingCTA key={`m-${APP_STRING.home}`} to={APP_ROUTE_KEY.home[0]} onClick={closeMenu} text={APP_STRING.home} mainColor={APP_COLOR.MOBILE.home} />
            <AdsLinkingCTA key={`m-${APP_STRING.landing_cta_digital_ads}`} to={APP_ROUTE_KEY.digital_ads[0]} onClick={closeMenu} text={APP_STRING.landing_cta_digital_ads} mainColor={APP_COLOR.MOBILE.digital_ads} />
            <AdsLinkingCTA key={`m-${APP_STRING.landing_cta_websites}`} to={APP_ROUTE_KEY.website[0]} onClick={closeMenu} text={APP_STRING.landing_cta_websites} mainColor={APP_COLOR.MOBILE.website} />
            <AdsLinkingCTA key={`m-${APP_STRING.landing_cta_content}`} to={APP_ROUTE_KEY.content[0]} isExternal={true} onClick={closeMenu} text={APP_STRING.landing_cta_content} mainColor={APP_COLOR.MOBILE.content} />
            <AdsLinkingCTA key={`m-${APP_STRING.landing_cta_bloomr}`} to={APP_ROUTE_KEY.bloomr[0]} isExternal={true} onClick={closeMenu} text={APP_STRING.landing_cta_bloomr} mainColor={APP_COLOR.MOBILE.bloomr} />
            <AdsLinkingCTA key={`m-${APP_STRING.landing_cta_contact}`} to={APP_ROUTE_KEY.contact[0]} onClick={closeMenu} text={APP_STRING.landing_cta_contact} mainColor={APP_COLOR.MOBILE.contact} />
        </section>
    )
}

const NavigationBar = () => {

    // const mappingHomes = APP_ROUTE_KEY.home.map(useRouteMatch);
    const [isShowMobileMenu, setShowMobileMenu] = useState(false);

    const onHamburgerClicked = useCallback(() => {
        setShowMobileMenu(!isShowMobileMenu);
    }, [isShowMobileMenu]);

    // let needShowLogo = true;
    // for (let key in mappingHomes) {
    //     let item = mappingHomes[key];
    // const isHomePage = item && item.isExact;
    // if (isHomePage) {
    //     needShowLogo = false;
    // }
    // }

    return (
        <nav className='mc-main-nav'>
            <NavLinks />
            <NavLogo />
            <button className='btn-burger'
                onClick={onHamburgerClicked}
            ></button>
            {isShowMobileMenu &&
                <MobileNavigation onClose={() => setShowMobileMenu(false)} />
            }

        </nav>
    )
}

const NavLogo = () => {
    return <div className='nav-logo'>
        <Link to={APP_ROUTE_KEY.home[0]}>
            <img alt='Home' src={APP_IMAGE.logo_min} />
            <span>{APP_STRING.top_left_nav_title}</span>
        </Link>
    </div>
}

const NavLinks = () => {
    console.log("APP_ROUTE_KEY.content[0]", APP_ROUTE_KEY.content[0])
    return <div className='nav-links'>
        <NavLink to={APP_ROUTE_KEY.digital_ads[0]} activeClassName='activelink' >{APP_STRING.main_navs[0]} </NavLink>
        <NavLink to={APP_ROUTE_KEY.website[0]} activeClassName='activelink'>{APP_STRING.main_navs[1]}</NavLink>
        <a href={APP_ROUTE_KEY.content[0]} target="_blank" activeClassName='activelink'>{APP_STRING.main_navs[2]}</a>
        <a href={APP_ROUTE_KEY.bloomr[0]} target="_blank" activeClassName='activelink'> {APP_STRING.main_navs[3]}</a>
        <NavLink to={APP_ROUTE_KEY.contact[0]} activeClassName='activelink'>{APP_STRING.main_navs[4]}</NavLink>
    </div>
}

export default NavigationBar;



import AdsModel from "../models/ads.model";
import FilterModel from "../models/filter.model";
import axios from 'axios';
import { APP_CONSTANT, APP_STRING } from "../constant";

const __caching = {
    category: {

    },
    filters: {
        formats: {},
        features: {},
        devices: {},
        industries: {},
    },
    adsResults: new Set()
}

const extractCategoryToFilter = (type = 'default', category = []) => {
    const feature = category.find(i => i.sub_cate_name === type);
    if (feature) {
        const parseItems = feature.sub_cate_options.map(item => new FilterModel({
            text: item.name,
            value: item.name
        }));
        return Promise.resolve(parseItems);
    }
    return Promise.resolve([]);
}

const APP_API = process.env.REACT_APP_API_ENDPOINT;

const detectPageType = (iaPage) => {
    let pageType = '';
    switch (iaPage) {
        case APP_STRING.page_digital_ads_name:
            pageType = 'Digital Ads';
            break;
        case APP_STRING.page_websites_name:
            pageType = 'Website';
            break;
        case APP_STRING.page_content_name:
            pageType = 'Content';
            break;
        case APP_STRING.page_bloomr_name:
            pageType = 'Bloomr.sg';
            break;
        default:
            break
    }
    return pageType;
}



const queueLoadCategory = {}

export default class ApiHelper {

    static GetCategoryByPage(pageType = 'default') {
        const url = APP_API + '/api/v1/category';
        pageType = detectPageType(pageType);

        if (!!__caching.category[pageType]) {
            return Promise.resolve(__caching.category[pageType]);
        }

        if (queueLoadCategory[pageType] !== undefined) {
            return new Promise(resolve => {
                queueLoadCategory[pageType].push(resolve);
            })
        }


        queueLoadCategory[pageType] = [];
        return axios.get(url, {
            params: {
                type: pageType
            }
        }).then(response => {
            var payload = response.data;
            if (payload.success) {
                __caching.category[pageType] = payload.response;
                setTimeout(() => {
                    queueLoadCategory[pageType].forEach(resolve => {
                        resolve(__caching.category[pageType]);
                    });
                });
                return Promise.resolve(__caching.category[pageType]);
            } else {
                return Promise.reject(pageType.message);
            }
        })
    }

    /**
     * 
     * @param {string} pageType
     * @return {Promise<[FilterModel]>} 
     */
    static GetFeatureFilterByPage(pageType = 'default') {
        return this
            .GetCategoryByPage(pageType)
            .then(category => {
                return Promise.resolve(extractCategoryToFilter('Feature', category));
            });
    }

    /**
     * 
     * @param {string} pageType
     * @return {Promise<[FilterModel]>} 
     */
    static GetDeviceFilterByPage(pageType = 'default') {
        return this
            .GetCategoryByPage(pageType)
            .then(category => {
                return Promise.resolve(extractCategoryToFilter('Device', category));
            });
    }

    /**
    * 
    * @param {string} pageType
    * @return {Promise<[FilterModel]>} 
    */
    static GetFormatFilterByPage(pageType = 'default') {
        return this
            .GetCategoryByPage(pageType)
            .then(category => {
                return Promise.resolve(extractCategoryToFilter('Format', category));
            });
    }

    /**
    * 
    * @param {string} pageType
    * @return {Promise<[FilterModel]>} 
    */
    static GetIndustryFilterByPage(pageType = 'default') {
        return this
            .GetCategoryByPage(pageType)
            .then(category => {
                return Promise.resolve(extractCategoryToFilter('Industry', category));
            });
    }

    /**
     * 
     * @param {{format: string, feature: string, device: string, industry: string}} param0 
     * @returns {Promise<{results: [AdsModel], nextPage:Function}>}
     */
    static FindAdsCardByFilter({ page = 'Digital Ads', format = 'default', feature = 'default', device = 'default', industry }) {

        const pageType = detectPageType(page);


        const getPayload = (offset) => {
            return {
                type: pageType,
                offset: offset,
                option: [['Format', format], ['Feature', feature], ['Device', device], ['Industry', industry]].filter(items => {
                    return items[1] != null && items[1].length > 0;
                }).map(items => {
                    return {
                        sub_cate: items[0],
                        option: items[1]
                    }
                }),

            };
        }
        const url = APP_API + '/api/v1/ads';
        let offset = 0, countLoop = 0, latestCardLength = 0;

        const takeResult = () => {
            if (countLoop > 0) {
                offset += latestCardLength;
            }
            countLoop++;
            const payload = getPayload(offset);
            return axios.post(url, payload)
                .then(response => {
                    var data = response.data;
                    if (data.success && data.response.length > 0) {
                        return Promise.resolve({
                            results: data.response.map(item => new AdsModel().fromJson(item)),
                            nextPage: data.response.length >= APP_CONSTANT.no_card_per_times
                                ? () => {
                                    latestCardLength = data.response.length;
                                    return takeResult();
                                } : null
                        })
                    }
                    return Promise.resolve({ results: [], nextPage: takeResult })
                })
        }

        return takeResult();
    }

    /**
     * 
     * @param {*} adsId 
     * @returns {Promise<AdsModel>}
     */
    static GetAdsDetailById(adsId) {
        const url = APP_API + '/api/v1/ads/' + adsId;

        return axios.get(url).then(({ data }) => {
            const { success, message, response } = data
            if (success) {
                return Promise.resolve(new AdsModel().fromDetailJson(response));
            }
            return Promise.reject(message);
        })
    }

    /**
     * 
     * @param {ContactModel} contactModel 
     */
    static NewContact(contactModel) {
        return axios.post(APP_API + '/api/v1/contactus', contactModel.toJson());
    }
}
import AppUtils from '../../utils/utils';
// import './yt-lib';



const YTUtils = {
    ParseVideoUrl2VideoId: (url = '') => {
        if (url.length === 0) {
            return url;
        } else if (url.search('youtube.com') === -1) {
            return url;
        } else if (url.indexOf("?") === -1) {
            return url;
        }
        const search = url.split("?")[1];
        const params = AppUtils.QueryStringParams(search);
        return params?.v || "";
    }
}


const ATTR_KEYS = {
    VIDEO_URL: 'data-video-url',
    AUTO_PLAY: 'data-auto-play',
    PAUSED: 'data-paused',
}

const YOUTUBE_STATUS = {
    unstarted: -1,
    ended: 0,
    playing: 1,
    paused: 2,
    buffering: 3,
    video_cued: 5
}

const JS_YOUTUBE_CDN = 'https://www.youtube.com/player_api';

const queueVideoRender = new Set(),
    queueLoad = new Set();

let isYoutubeAPIReady = false;

let hasLoadedScript = false,
    isLoadingScript = false;

function loadYoutubeScript(cb) {
    if (hasLoadedScript) {
        cb();
        return;
    } else if (isLoadingScript) {
        queueLoad.add(cb);
        return;
    }
    queueLoad.add(cb);
    isLoadingScript = true;
    const script = document.createElement("script");
    script.src = JS_YOUTUBE_CDN;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

    script.onload = () => {
        hasLoadedScript = true;
        queueLoad.forEach(f => {
            if (typeof f === 'function')
                f();
        })
    }
    script.onerror = () => {
        setTimeout(() => {
            loadYoutubeScript(cb);
        }, 1000);
    }
}


window.onYouTubePlayerAPIReady = () => {
    isYoutubeAPIReady = true;
    queueVideoRender.forEach(cb => {
        if (typeof cb === 'function') {
            cb()
            queueVideoRender.delete(cb);
        }
    });
}

class MCYoutubePlayer extends HTMLElement {

    static get observedAttributes() {
        return Object.values(ATTR_KEYS);
    }

    constructor() {
        super();
        this.ytPlayer = null;
        this.meta = {
            videoId: '',
            autoPlay: false,
            isMute: true,
            paused: false,
            hasRendered: false
        }
        this.render = this.render.bind(this);
        this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
        this.initData();
    }

    initData() {
        this.meta.videoId = YTUtils.ParseVideoUrl2VideoId(this.getAttribute('data-video-url') || '');
    }

    render() {
        if (isYoutubeAPIReady === false) {
            queueVideoRender.add(this.render);
            return;
        }
        this.ytPlayer = document.createElement('iframe');
        this.ytPlayer = new window.YT.Player(this, {
            height: '100%',
            width: '100%',
            videoId: this.meta.videoId,
            playerVars: {
                playsinline: 1,
                modestbranding: 0,
                loop: 0,
                autoplay: this.meta.autoPlay,
                enablejsapi: 1
            },
            events: {
                onStateChange: this.onPlayerStateChange
            }
        });
    }

    onPlayerStateChange({ target, data }) {
        if (data === YOUTUBE_STATUS.playing) {
            this.meta.paused = false;
        } else if (data === YOUTUBE_STATUS.paused) {
            this.meta.paused = true;
        }
    }

    connectedCallback() {
        if (!this.meta.hasRendered) {
            this.meta.hasRendered = true;
            loadYoutubeScript(this.render)
        }
    }

    /**
     * 
     * @param {string} videoSrc 
     */
    setVideoSrc(videoSrc) {
        const nextVideoId = YTUtils.ParseVideoUrl2VideoId(videoSrc);
        if (nextVideoId === this.meta.videoId) {
            return;
        }
        this.meta.videoId = nextVideoId;
        if (this.ytPlayer != null) {
            this.ytPlayer.loadVideoById(this.meta.videoId, 0);
        }
    }

    setPauseVideo(flag) {
        this.meta.paused = flag
        if (this.ytPlayer == null) {
            return;
        }

        if (flag && this.ytPlayer.pauseVideo !== undefined) {
            this.ytPlayer.pauseVideo();
        } else if (!flag && this.ytPlayer.playVideo !== undefined) {
            this.ytPlayer.playVideo();
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case ATTR_KEYS.VIDEO_URL:
                this.setVideoSrc(newValue);
                break;
            case ATTR_KEYS.PAUSED:
                this.setPauseVideo(Boolean(newValue));
                break;
            default: break;
        }

    }
}

window.customElements.define('mc-ytplayer', MCYoutubePlayer);

import './landing.page.scss';
import AdsLinkingCTA from '../../components/ads-linking-cta/ads-linking-cta';
import { APP_COLOR, APP_CONSTANT, APP_ROUTE_KEY } from '../../constant';
import APP_STRING from '../../constant/strings';
import MCPage from '../../widgets/mc-page/mc-page';
import AppFooter from '../../components/footer/footer'
import useSetClassRoot from '../../hooks/useSetClassRoot';
import MCDescription from '../../widgets/description/mc-description';
import { useEffect, useState } from 'react';
import { isLoadingShowing } from '../../hooks/useFullLoading';

const LandingPage = () => {

    useSetClassRoot({ className: 'page-landing' });

    const [isShowMenu, setShowMenu] = useState(false);

    useEffect(() => {
        let waiter;
        if (isLoadingShowing()) {
            waiter = setTimeout(() => {
                setShowMenu(true);
            }, APP_CONSTANT.SPLASH_DURATION || 3000);
        } else {
            waiter = setTimeout(() => {
                setShowMenu(true);
            }, 0);
        }
        return () => {
            clearTimeout(waiter);
        }
    }, []);

    return (
        <MCPage className='app-page'>
            <section className='section-logo'>
                <div className='imv-logo'></div>
                <span className='lb-title'>{APP_STRING.landing_title}</span>
            </section>
            <section className='section-description'>
                <MCDescription content={APP_STRING.landing_description} />
            </section>
            <section className={`section-ads-nav${isShowMenu ? ' show-menu' : ''}`}>
                <AdsLinkingCTA key={APP_STRING.landing_cta_digital_ads + '_' + isShowMenu} className='animate__bounceInLeft' to={APP_ROUTE_KEY.digital_ads[0]} text={APP_STRING.landing_cta_digital_ads} mainColor={APP_COLOR.digital_ads} />
                <AdsLinkingCTA key={APP_STRING.landing_cta_websites + '_' + isShowMenu} className='animate__bounceInRight' to={APP_ROUTE_KEY.website[0]} text={APP_STRING.landing_cta_websites} mainColor={APP_COLOR.website} />
                <AdsLinkingCTA key={APP_STRING.landing_cta_content + '_' + isShowMenu} className='animate__bounceInLeft' to={APP_ROUTE_KEY.content[0]} isExternal={true} text={APP_STRING.landing_cta_content} mainColor={APP_COLOR.content} />
                <AdsLinkingCTA key={APP_STRING.landing_cta_bloomr + '_' + isShowMenu} className='animate__bounceInRight' to={APP_ROUTE_KEY.bloomr[0]} isExternal={true} text={APP_STRING.landing_cta_bloomr} mainColor={APP_COLOR.bloomr} />
                <AdsLinkingCTA key={APP_STRING.landing_cta_contact + '_' + isShowMenu} className='animate__bounceInLeft' to={APP_ROUTE_KEY.contact[0]} text={APP_STRING.landing_cta_contact} mainColor={APP_COLOR.contact} />
            </section>
            <AppFooter showEntries={[APP_CONSTANT.footer_show_all]} mailSharing={
                {
                    title: 'Digital Gallery',
                    body: APP_STRING.landing_sharing.join(APP_CONSTANT.MAIL_NEW_LINE),
                    url: window.location.origin
                }
            } whatappSharing={
                {
                    title: 'Digital Gallery',
                    body: APP_STRING.landing_sharing.join(APP_CONSTANT.WHATAPP_NEW_LINE),
                    url: window.location.origin
                }
            } />
        </MCPage>
    )
}

export default LandingPage;

import { APP_COLOR, APP_CONSTANT, APP_STRING } from "../../../constant"
import AdsFilterBasePage from "../base-ads.page";
import useSetClassRoot from "../../../hooks/useSetClassRoot";

const ContentPage = () => {
    useSetClassRoot({className: 'page-content'})
    return (
        <AdsFilterBasePage
            pageName={APP_STRING.page_content_name}
            mainColor={APP_COLOR.content}
            showFilterEntries={[APP_CONSTANT.ADS_FILTER.show_format, APP_CONSTANT.ADS_FILTER.show_feature, APP_CONSTANT.ADS_FILTER.show_industry]}
        />
    )
}

export default ContentPage;
import './footer.scss';
import { memo } from "react";
import { APP_CONSTANT, APP_STRING } from "../../constant";
import { SharingUtil } from '../../utils/sharing.util';
import AppUtils from '../../utils/utils';

const areEqual = (prevProps, nextProps) => {
    for (let k in nextProps.showEntries) {
        let item = nextProps.showEntries[k];
        if (prevProps.showEntries.indexOf(item) === -1) {
            return false;
        }
    }
    return true;
}

const AppFooter = ({
    showEntries = [APP_CONSTANT.footer_show_logo, APP_CONSTANT.footer_show_contact, APP_CONSTANT.footer_show_sharing],
    mailSharing = { title: '', body: '', url: '' },
    whatappSharing = { title: '', body: '', url: '' },
    ...rest }) => {

    let needShowLogo,
        needShowContact,
        needShowSharing;

    if (showEntries.indexOf('all') > -1) {
        needShowLogo = true;
        needShowContact = true;
        needShowSharing = true;
    } else {
        needShowLogo = showEntries.indexOf(APP_CONSTANT.footer_show_logo) > -1;
        needShowContact = showEntries.indexOf(APP_CONSTANT.footer_show_contact) > -1;
        needShowSharing = showEntries.indexOf(APP_CONSTANT.footer_show_sharing) > -1;
    }

    return (
        <footer className='mc-app-footer'>
            { needShowLogo && <WrapLogo />}
            { needShowContact && <WrapContact />}
            { needShowSharing && <WrapSharing mailSharing={mailSharing} whatappSharing={whatappSharing} />}
        </footer>
    )
}

const WrapLogo = () => {
    return (
        <div className='wrap wrap-logo'></div>
    )
}

const WrapContact = () => {
    return (
        <div className='wrap wrap-contact'>
            <div className='row'>
                <span>{APP_STRING.footer_address}</span>
            </div>
            <div className='row'>
                <span>{APP_STRING.footer_phone}</span>
                <span>{APP_STRING.footer_email}</span>
            </div>
            <div className='row'>
                <span>{APP_STRING.footer_copy_right.replace('#YEAR', new Date().getFullYear())}</span>
            </div>
            <div className='row wrap-link'>
                <span><a href={APP_STRING.footer_vulnerability_disclosure_url} target='_blank' >{APP_STRING.footer_vulnerability_disclosure_replacement}</a></span>
            </div>
        </div>
    )
}

const WrapSharing = ({ mailSharing = { title: '', body: '', url: '' }, whatappSharing = { title: '', body: '', url: '' } }) => {
    return (
        <div className='wrap wrap-sharing'>
            <a className='btn-sharing btn-sharing-mail' href={SharingUtil.FormatMailContent(mailSharing.title, mailSharing.body, mailSharing.url)}></a>
            <a target={AppUtils.IsMobile() ? '_self' : '_blank'} className='btn-sharing btn-sharing-whatsapp' href={SharingUtil.FormatWhatsAppContent(whatappSharing.title, whatappSharing.body, whatappSharing.url)}></a>
        </div>
    )
}

export default memo(AppFooter, areEqual);
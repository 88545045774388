import './ads-card.scss';
import { memo } from "react"
import AdsModel from "../../models/ads.model";
import { Link } from 'react-router-dom';
import { APP_ROUTE_KEY } from '../../constant';


const areEqual = (prevProps, nextProps) => {
    return prevProps.adsModel.id === nextProps.adsModel.id
        && prevProps.canShow === nextProps.canShow
    // && onAd
}

/**
 * 
 * @param {{adsModel: AdsModel}} param0 
 */
const AdsCard = ({ key, adsModel, className = '', canShow = true }) => {
    return (
        <div key={key}
            className={`ads-card ${className}`}>

            <Link to={{
                pathname: APP_ROUTE_KEY.ads_detail[0],
                search: `?id=${adsModel.id}`
            }} >
                <div className='ads-thumb' style={{ paddingTop: (adsModel.thumbSize.height > 0 ? (adsModel.thumbSize.height * 100 / adsModel.thumbSize.width) : 100) + '%' }}>
                    <img alt={adsModel.projectName} className='ads-img'
                        src={adsModel.thumbnail}
                    />
                </div>

                <div className='ads-info'>
                    <span className='row lb-client-name'>{adsModel.client.name}</span>
                    <span className='row lb-project-title'>{adsModel.formats.join(', ')}</span>
                </div>

                <AdsTypeIcon adsType={adsModel.adsType} />

            </Link>
        </div >
    )
}

const AdsTypeIcon = ({ adsType }) => {
    return (
        <>
            {adsType === AdsModel.ADS_TYPE.video && <div className='ads-type-icon ads-type-video'></div>}
            {adsType === AdsModel.ADS_TYPE.podcast && <div className='ads-type-icon ads-type-podcast'></div>}
        </>
    )
}

export default memo(AdsCard, areEqual);
import './mc-page.scss';
import { forwardRef } from "react";


const MCPage = forwardRef(({ children, className = '', ...rest }, ref) => {
    let classNames = 'mc-page';
    if (className.length > 0) {
        classNames += ' ' + className;
    }
    return (
        <div className={classNames}>
            { children}
        </div >
    )
});

export default MCPage;

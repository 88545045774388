import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, compose, createStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { _getReducer } from './redux/reducer';
import { runner } from './redux';
import './web-components/index';


const sagaMiddleware = createSagaMiddleware();
const rootStore = createStore(_getReducer(), [], compose(
    applyMiddleware(sagaMiddleware)
));
sagaMiddleware.run(runner);

ReactDOM.render(
    <Provider store={rootStore}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('mc-app-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

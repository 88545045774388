
import { useState } from 'react';
import './mc-checkbox.scss'

let checkId = 1;

const MCCheckbox = ({ value, checked, onChange }) => {

    const [cbId] = useState(checkId++);

    return (
        <div className='mc-checkbox'>
            <input id={cbId} type="checkbox" value={value} onChange={onChange} />
            <label htmlFor={cbId}></label>
        </div>
    )
}

export default MCCheckbox;
import { memo } from 'react';
import { APP_CONSTANT, APP_IMAGE } from "../../constant";
import AppUtils from "../../utils/utils";
import AdsFilter from "../ads-filter/ads-filter";
import './filter-banner.scss';


const areEqual = (prevProps, nextProps) => {
    return prevProps.name === nextProps.name
        && prevProps.imageSrc === nextProps.imageSrc
        && prevProps.mainColor === nextProps.mainColor
        && prevProps.onFilterApplied === nextProps.onFilterApplied
        && AppUtils.AreArrayEqual(prevProps.formatFilters, nextProps.formatFilters)
        && AppUtils.AreArrayEqual(prevProps.featureFilters, nextProps.featureFilters)
        && AppUtils.AreArrayEqual(prevProps.deviceFilters, nextProps.deviceFilters)
        && AppUtils.AreArrayEqual(prevProps.industryFilters, nextProps.industryFilters)
        && AppUtils.AreArrayEqual(prevProps.showEntries, nextProps.showEntries)

}

const FilterBanner = ({
    name = "page",
    imageSrc = APP_IMAGE.default_ads_banner,
    mainColor = '#000000',
    formatFilters = [],
    featureFilters = [],
    deviceFilters = [],
    industryFilters = [],
    defaultFormatValue = '',
    defaultFeatureValue = '',
    defaultDeviceValue = '',
    defaultIndustryValue = '',
    showEntries = [APP_CONSTANT.ADS_FILTER.show_all],
    onFilterApplied
}) => {
    if (mainColor.indexOf('#') !== -1 && mainColor.length === 7) {
        mainColor = AppUtils.HexToRGB(mainColor, 0.6)
    }

    const styles = {
        'backgroundImage': imageSrc.length > 0 ? `url('${imageSrc}')` : 'inherit',
    }

    return (
        <div className='mc-filter-banner' style={styles} >
            <div className='wrapper' style={{ backgroundColor: mainColor }}>
                <span className='lb-page-name'>{name}</span>
                <AdsFilter
                    deviceFilters={deviceFilters}
                    featureFilters={featureFilters}
                    formatFilters={formatFilters}
                    industryFilters={industryFilters}
                    showEntries={showEntries}
                    onFilterApplied={onFilterApplied}
                    defaultFormatValue={defaultFormatValue}
                    defaultFeatureValue={defaultFeatureValue}
                    defaultDeviceValue={defaultDeviceValue}
                    defaultIndustryValue={defaultIndustryValue}
                />
            </div>
        </div>
    )
}

export default memo(FilterBanner, areEqual);
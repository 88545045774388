import BaseModel from "./base.model";

class FilterModel extends BaseModel {
    text = 'Text';
    value = 'Text'

    constructor({ text = '', value = '' }) {
        super();
        this.text = text;
        this.value = value;
    }

    toSelectOption() {
        return { value: this.value, label: this.text };
    }

    random() {
        let faker = this.getFaker();
        if (faker) {
            this.value = faker.name.title();
            this.text = this.value;
        }
        return this;
    }

    /**
     * 
     * @param {[FilterModel]} filterList 
     * @returns {[{label,value}]}
     */
    static ConvertFiltersToOptions(filterModels) {
        return filterModels.map(item => item.toSelectOption());
    }
}

export default FilterModel;
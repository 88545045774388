import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select'
import './mc-dropdown.scss';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: '#000',
        fontSize: '.9rem',
        padding: '.6rem 5px',
        cursor: 'pointer',
        "@media only screen and (max-width: 1025px)": {
            fontSize: '1.5rem',
        },
    }),
    container: (provided) => ({
        ...provided,
        borderRadius: 'none',
    }),
    control: () => ({
        height: '2rem',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        "@media only screen and (max-width: 1025px)": {
            height: '3.2rem',
        },
    }),
    valueContainer: () => ({
        height: '100%',
        paddingLeft: '5px',
        paddingRight: '2rem'
    }),
    singleValue: ((provided) => ({
        ...provided,
        color: '#000',
        fontSize: '.9rem',
        maxWidth: 'calc(100% - 2rem)',
        "@media only screen and (max-width: 1025px)": {
            fontSize: '1.5rem',
        },
    }))
}

const MCDropdown = ({ defaultValue = { label: 'Select', value: '' }, options = [], className = '', onChange }) => {

    const [value, setValue] = useState(defaultValue);

    const cbChange = useCallback((selectedOption) => {
        if (onChange) {
            onChange(selectedOption);
        }
        setValue(selectedOption);
    });

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    // const [defValue, setDefValue] = useState(defaultValue);

    // useEffect(() => setValue(defaultValue), [defaultValue]);

    return (
        <Select
            className={'mc-dropdown ' + className}
            defaultValue={value}
            value={value}
            options={options}
            styles={customStyles}
            isSearchable={false}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            onChange={cbChange}
        />
    )
}

export default MCDropdown;
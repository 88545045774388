import { forwardRef, useCallback } from 'react';
import './mc-textarea.scss';

const MCTextArea = forwardRef(({ type = 'text', value = '', inputKey = '', errorMsg = '', placeHolder = '', rows = 1, onTextChange = () => { }, ...rest }, ref) => {

    const onChange = useCallback((e) => {
        onTextChange(e.target.value, inputKey);
    }, [inputKey, onTextChange]);

    return (
        <div className='mc-textarea'>
            <div className='mc-input__holder'>
                <textarea ref={ref} placeholder={placeHolder} onChange={onChange} rows={rows} />
            </div>
            {errorMsg.length > 0 &&
                <span class='lb-error'>{errorMsg}</span>}
        </div >
    )
})

export default MCTextArea;
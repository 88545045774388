import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import './ads-linking-cta.scss';

const areEqual = (prevProps, nextProps) => {
    return prevProps.mainColor === nextProps.mainColor
        && prevProps.text === nextProps.text
        && prevProps.to === nextProps.to
}

const CTA = ({ mainColor, children, ...rest }) => {
    const BTN = styled.button`
    &::after{
        background-color: ${mainColor};
    }`;
    return <BTN {...rest}>{children}</BTN>
}

const AdsLinkingCTA = ({ mainColor = 'rgba(0,0,0,0.1)', text = 'text', to = '#', target = '_blank', isExternal = false, onClick = () => { }, className = '', ...rest }) => {

    const history = useHistory();
    return <CTA {...rest} className={`ads-linking-cta animate__animated ${className}`} mainColor={mainColor} onClick={() => {
        if (isExternal) {
            window.open(to, target);
            return;
        }
        onClick();
        history.push(to);
    }} >
        <span className='dim'></span>
        <span>{text}</span>
    </CTA >
};

export default memo(AdsLinkingCTA, areEqual);
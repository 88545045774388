
const APP_COLOR = {
    digital_ads: '#00aeef',
    content: '#f26c4f',
    website: '#991c78',
    bloomr: '#41b649',
    contact: '#363636',
    MOBILE: {
        home: '#3688a7',
        digital_ads: '#2dd1ee',
        content: '#9aaaa4',
        website: '#6a6cc8',
        bloomr: '#47d3a1',
        contact: '#3688a7',
    }
}

export default APP_COLOR;